import { Button, IconDownload, IconPhone, Link } from 'shared'
import { PREMIUM_SWITCH_STEP } from './SwitchModalPremiumService'

const PREMIUM_CONTACT_ADVISER_ENABLED =
  process.env.REACT_APP_FEATURE_TOGGLE_PREMIUM_CONTACT_ADVISER === 'true'

type PremiumServiceDownloadPdfProps = {
  setCurrentStep: (step: PREMIUM_SWITCH_STEP) => void
  onClose: () => void
}

export const PremiumServiceDownloadPdf = ({
  setCurrentStep,
  onClose,
}: PremiumServiceDownloadPdfProps) => {
  const renderRequestACallButton = () =>
    PREMIUM_CONTACT_ADVISER_ENABLED ? (
      <Button
        className="ml-lg"
        variant="filled"
        aria-controls="account-options-menu"
        aria-haspopup="true"
        onClick={() => setCurrentStep(PREMIUM_SWITCH_STEP.CONTACT_FORM)}
      >
        <IconPhone /> Request a call
      </Button>
    ) : (
      <Link
        className="ml-lg"
        variant="filled"
        target="_blank"
        rel="noreferrer noopener"
        to="https://fisherfunds.co.nz/talk-to-an-adviser"
      >
        <IconPhone /> Request a call
      </Link>
    )

  return (
    <>
      <p>
        To change your investment options, download and complete the switch
        form. Or if you'd like to chat to your adviser, you can request a call.
      </p>
      <div className="flex-row-justify-content-space-between mt-lg">
        <Button
          variant="link"
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={onClose}
        >
          Cancel
        </Button>
        <div className="flex-row">
          <Link
            className="download-form"
            variant="outlined"
            target="_blank"
            rel="noreferrer noopener"
            to="https://assets.fisherfunds.co.nz/premium-service-ff688-premium-service-investment-switch-form"
          >
            <IconDownload className="icon_download" /> Download the form
          </Link>
          {renderRequestACallButton()}
        </div>
      </div>
    </>
  )
}
