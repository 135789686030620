import { WithdrawalFormFields } from '../WithdrawFundsModalGeneric'

type MFWithdrawReviewProps = {
  values: WithdrawalFormFields
  onBack: () => void
  onConfirm: () => void
}

export const MFWithdrawReview = ({
  values,
  onBack,
  onConfirm,
}: MFWithdrawReviewProps) => {
  return <div>COSO</div>
}
