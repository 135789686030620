import { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import { IInvestmentOption, Notification } from 'shared'
import { useProgressBarContext } from '../../hooks/useProgressBar'
import IPQIntroScreen from './IPQIntroScreen'
import {
  IPQQuestionnaire,
  getIPQQuestionnaire,
  initIPQ,
  updateIPQQuestionnaire,
} from './utils'
import {
  IPQFormValues,
  IPQQuestion,
  IPQPutResponse,
  IPQStep,
  IPQTemplate,
} from './ipq.model'
import { connect } from 'react-redux'
import Modal from '../modal/Modal'
import IPQQuestionSection, {
  IPQQuestionSectionProps,
} from './IPQQuestionSection'
import IPQResult from './IPQResult'
import AdvicePercentageLine from '../advice/AdvicePercentageLine'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import { Account } from '../../redux/accounts/accounts.model'
import {
  getAccountByID,
  isKiwiSaverPlanAccount,
  isInvestmentFundsAccount,
} from '../../common/accounts-helper'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import {
  getInvestmentOptionByCode,
  isCustomInvestment,
} from '../../utils/investmentOption'
import AdviceDisclosure from '../advice/AdviceDisclosure'
import { UserData } from '../../redux/user/user.model'
import { AppState } from '../../redux/app-state'
import './IPQ.scss'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import {
  Allocation,
  calculateFundsAllocations,
  mapAccountDetailsFundsToAllocations,
} from '../../utils/fund'
import { containsGlidePath } from '../../utils/strategy'
import { Mandate, getMandate } from '../../api/mandate/mandate-api'
import SwitchPendingErrorMessage from '../switch-contentful-modal/SwitchPendingErrorMessage'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'

const TITLES = {
  INTRO: 'Investor Profile Questionnaire',
  RESULT: 'Your results',
}

const templateId = parseInt(process.env.REACT_OMNIMAX_IPQ_TEMPLATE_ID) || 10

type PropsFromRedux = {
  accountDetails: AccountDetail
  user: UserData
  authToken: string
  currentFundAllocations: Allocation[]
  futureFundAllocations: Allocation[]
}

export type IPQProps = {
  account: Account
  onClose: () => void
  onResult?: (investmentOption: IInvestmentOption) => void
} & IPQTemplate &
  Omit<IPQQuestionSectionProps, 'onNext'>

const IPQ = ({
  onClose,
  accountDetails,
  account,
  currentFundAllocations,
  futureFundAllocations,
  user,
  authToken,
  onResult,
}: IPQProps & PropsFromRedux) => {
  const [IPQSection, setIPQSection] = useState<number>(0)
  const {
    step,
    setStep,
    total,
    setTotal,
    progress,
    setProgress,
  } = useProgressBarContext()
  const [ipqQuestionnaire, setIPQQuestionnaire] = useState<IPQQuestionnaire>()
  const [dalId, setDalId] = useState<string | null>(null)
  const [ipqResult, setIPQResult] = useState<IPQPutResponse>()
  const [modalSize, setModalSize] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
  const [currentInvestment, setCurrentInvestment] = useState<
    InvestmentOptionType
  >()
  const [loading, setLoading] = useState<boolean>(false)
  const [mandate, setMandate] = useState<Mandate>(null)

  const IPQSections = ipqQuestionnaire?.sections
  const IPQQuestions = useMemo(
    () =>
      IPQSections?.reduce<IPQQuestion[]>(
        (acc, section) => acc.concat(section.questions as IPQQuestion[]),
        []
      ),
    [IPQSections]
  )

  const createDalId = () => {
    initIPQ(account, user, authToken).then((response) => {
      setDalId(response)
    })
  }

  useEffect(() => {
    setTotal(IPQQuestions?.length || 0)
  }, [IPQQuestions, setTotal])

  useEffect(() => {
    setLoading(true)
    setStep(IPQStep.INTRO_SCREEN)
    getIPQQuestionnaire(templateId, authToken).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        setLoading(false)
        return setShowErrorMessage(true)
      }
      setLoading(false)
      setIPQQuestionnaire(response)
      createDalId()
    })
  }, [templateId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step !== IPQStep.QUESTIONNAIRE && progress !== 0) {
      setProgress(0) // Reset progress when moving to a different screen.
    }
  }, [step, progress, setProgress])

  useEffect(() => {
    if (isKiwiSaverPlanAccount(account)) {
      getMandate(account.accountID, authToken)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw Error()
          }
          setMandate(response.data)
        })
        .catch(() => {
          setShowErrorMessage(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { contentfulEntry: product } = useContentfulProduct(
    account.productExternalName
  )
  useEffect(() => {
    const { profileCode, profileName } = accountDetails || {}
    if (!product || !profileCode) {
      return
    }

    const investment = getInvestmentOptionByCode(
      product,
      containsGlidePath(profileName) ? 'GlidePath' : profileCode
    )
    setCurrentInvestment({
      ...investment,
      isCustom: isCustomInvestment(investment),
      allocations: currentFundAllocations,
      futureAllocations: futureFundAllocations,
    } as InvestmentOptionType)
  }, [product, accountDetails, currentFundAllocations, futureFundAllocations])

  const onSubmitIPQResults = (investmentOption: IInvestmentOption) => {
    if (mandate && !mandate.isMandateChangeAllowed) {
      setModalSize('')
      return setStep(IPQStep.SWITCH_PENDING)
    }
    updateIPQQuestionnaire(dalId, authToken, 'Complete', account, {
      type: 'Switch',
      option: investmentOption.shortName,
    }).then((res) => {
      setIPQResult(res)
    })
    if (onResult) {
      onResult(investmentOption)
    }
  }

  const onRemain = (investmentOption: IInvestmentOption) => {
    updateIPQQuestionnaire(dalId, authToken, 'Complete', account, {
      type: 'Remain',
      option: investmentOption.shortName,
    }).then((res) => {
      setIPQResult(res)
    })
    onClose()
  }

  const handleSubmitIPQ = (values: IPQFormValues) => {
    updateIPQQuestionnaire(
      dalId,
      authToken,
      'Complete',
      account,
      null,
      values,
      templateId
    ).then((res) => {
      setIPQResult(res)
    })
  }

  const handleResetIPQ = () => {
    setDalId(null)
    createDalId()
    setModalSize('')
    setStep(IPQStep.INTRO_SCREEN)
  }

  const handleStepNext = (data?: IPQFormValues) => {
    switch (step) {
      case IPQStep.INTRO_SCREEN: {
        setStep(IPQStep.QUESTIONNAIRE)
        break
      }

      case IPQStep.QUESTIONNAIRE: {
        handleSubmitIPQ(data as IPQFormValues)
        setStep(IPQStep.RESULTS)
        break
      }

      default:
        return
    }
  }

  const handleStepBack = () => {
    if (IPQSection <= 0) {
      setStep(IPQStep.INTRO_SCREEN)
      return
    }
    setIPQSection(IPQSection - 1)
  }

  const handleOptionsChange = (needsLargeModal: boolean) => {
    setModalSize(needsLargeModal ? 'large' : '')
  }

  const renderStep = () => {
    switch (step) {
      case IPQStep.RESULTS:
        return (
          <IPQResult
            product={product}
            result={ipqResult}
            onCancel={onRemain}
            onSelect={onSubmitIPQResults}
            onReset={handleResetIPQ}
            onOptionsChange={handleOptionsChange}
            currentInvestment={currentInvestment}
          />
        )
      case IPQStep.SWITCH_PENDING:
        return (
          <SwitchPendingErrorMessage
            onClose={onClose}
            lastSwitchRequest={mandate?.addedDate}
          />
        )

      case IPQStep.QUESTIONNAIRE:
        return (
          <IPQQuestionSection
            templateId={templateId}
            dalId={dalId}
            questions={IPQQuestions}
            onBack={handleStepBack}
            handleNext={handleStepNext}
          />
        )
      case IPQStep.INTRO_SCREEN:
      default:
        return (
          <IPQIntroScreen
            loading={loading || (isKiwiSaverPlanAccount(account) && !mandate)}
            onNext={handleStepNext}
            onClose={onClose}
            isFundProduct={
              isKiwiSaverPlanAccount(account) ||
              isInvestmentFundsAccount(account)
            }
          />
        )
    }
  }

  const renderNotifications = () => {
    return (
      <>
        {!!showErrorMessage && (
          <Notification className="mb-sm" type="error">
            Sorry, we appear to be experiencing problems processing your
            request. Please try again in a few minutes or get in touch with us
            on {COMPANY_PHONE_NUMBER}.
          </Notification>
        )}
      </>
    )
  }

  const title = step === IPQStep.RESULTS ? TITLES.RESULT : TITLES.INTRO
  const percentageComplete = Math.trunc((progress / total) * 100)

  return (
    <Modal
      className={cn('ipq', modalSize)}
      title={title}
      open
      onClose={onClose}
    >
      {renderNotifications()}
      {!showErrorMessage && (
        <>
          <div className="ipq__form">{renderStep()}</div>
          <AdvicePercentageLine
            className="ipq__modal"
            percentageComplete={percentageComplete}
          />
          <AdviceDisclosure />
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = (state: AppState, props: any) => {
  const accountDetails = getAccountByID(
    props.account.accountID,
    state.accountDetails.accounts
  )
  return {
    accountDetails: accountDetails?.profile,
    currentFundAllocations: calculateFundsAllocations(state.accountFunds.funds),
    futureFundAllocations: mapAccountDetailsFundsToAllocations(
      accountDetails?.profile?.funds
    ),
    authToken: state.authorisation.authorisationToken,
    user: state.user,
  }
}

export default connect(mapStateToProps, null)(IPQ)
