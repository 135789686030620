import { Button, TextAreaInput } from 'shared'
import { useForm } from 'react-hook-form'
import Alert from 'react-s-alert'
import './PremiumServiceContactForm.scss'
import { Account } from '../../redux/accounts/accounts.model'
import { premiumServiceSwitchRequestACall } from '../../api/advice/advice-api'
import { useState } from 'react'
import { UserData } from '../../redux/user/user.model'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'

interface SwitchModalPremiumServiceProps {
  authToken: string
  account: Account
  user: UserData
  onCancel: () => void
  onClose: () => void
}

const PremiumServiceContactForm = ({
  authToken,
  account,
  user,
  onCancel,
  onClose,
}: SwitchModalPremiumServiceProps) => {
  const { register, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitEvent = async (data: any) => {
    setIsLoading(true)

    try {
      await premiumServiceSwitchRequestACall(
        authToken,
        account.accountNumber,
        user.userid,
        data.message
      )
      Alert.success(
        `<h6>Thanks!</h6><p> We've recieved your request and will be in touch.</p>`,
        { timeout: 15000 }
      )

      onClose()
    } catch (error) {
      Alert.error(
        `Oops, something has gone wrong! Please try again shortly. If this keeps happening, please get in touch on 0508 347 437 or email us on <a href="mailto:enquiries@fisherfunds.co.nz" class="color-dusk">enquiries@fisherfunds.co.nz</a>`,
        { timeout: 1500000 }
      )
    }
    setIsLoading(false)
  }

  return (
    <div className="premium-service-contact-form">
      <SpinnerOnLoad
        className="spinner"
        isLoading={isLoading}
        centerSpinner={true}
        showMessageAfter={5000}
      >
        <h5>Request a call</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <form
          className="switch-premium-form flex-column"
          onSubmit={handleSubmit(onSubmitEvent)}
        >
          <TextAreaInput
            id="message"
            type="message"
            placeholder="Message (optional)"
            {...{
              ...register('message'),
              ref: undefined,
              inputRef: register('message', {
                maxLength: 200,
              }).ref,
            }}
          />
          <div className="flex-row-justify-content-space-between">
            <Button
              className="mt-md"
              variant="link"
              aria-controls="account-options-menu"
              aria-haspopup="true"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className="mt-md"
              type="submit"
              variant="filled"
              aria-controls="account-options-menu"
              aria-haspopup="true"
            >
              <span>Send</span>
            </Button>
          </div>
        </form>
      </SpinnerOnLoad>
    </div>
  )
}

export default PremiumServiceContactForm
