import React, { useEffect } from 'react'
import './AccountPerformanceTab.scss'
import AccountPerformanceSummary from '../account-performance-summary/AccountPerformanceSummary'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import TabPanel from '../tab-panel/TabPanel'
import { AppState } from '../../redux/app-state'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import { bindActionCreators, Dispatch } from 'redux'
import {
  AccountDetailsActions,
  AccountDetailsRequestAction,
} from '../../redux/account-details/account-details.actions'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import BalanceGraphFilters from '../balance-graph/BalanceGraphFilters'
import BalanceGraph from '../balance-graph/BalanceGraph'
import { REINVESTED_DISTRIBUTIONS_SECTION_NAME } from '../account-performance-summary/AccountPerformanceSummarySection'

type Section = {
  details: [{ detailLabel: string; detailTotal: number }]
  label: string
  total: number
  color: string
}

interface AccountPerformanceTabProps {
  userId: string
  hasError: boolean
  isLoading: boolean
  sections?: Section[]
  currentBalance: number
  currentAccount: Account
  getAccountDetails: (accountId: string, userId: string) => void
  params: {
    id: string
  }
  errorMessage: string
}

function AccountPerformanceTab(props: AccountPerformanceTabProps) {
  const {
    params,
    userId,
    isLoading,
    getAccountDetails,
    hasError,
    currentAccount,
  } = props

  useEffect(() => {
    if (params.id && userId && !isLoading && !hasError) {
      getAccountDetails(params.id, userId)
    }
  }, [params.id, userId, isLoading, getAccountDetails, hasError])

  return (
    <TabPanel value={0} index={0}>
      <div className="account-performance-tab-component">
        <SpinnerOnLoad
          className="spinner"
          isLoading={props.isLoading}
          centerSpinner={true}
          showMessageAfter={5000}
        >
          <BalanceGraphFilters
            accountCommenceDate={currentAccount.commenceDate}
          />
          <BalanceGraph
            accountNumber={currentAccount.accountNumber}
            userId={userId}
          />

          <AccountPerformanceSummary
            sections={props.sections}
            currentBalance={props.currentBalance}
          />
        </SpinnerOnLoad>
      </div>
    </TabPanel>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountPerformanceTabProps
) => {
  const cachedAccountDetails = state.accountDetails.accounts.find(
    (acc: AccountDetail) => acc.accountID === props.params.id.toString()
  )
  // Retrieve the cached account details. If there aren't any then create some empty account details.
  const accountDetails: AccountDetail =
    cachedAccountDetails ||
    ({
      accountNumber: null,
      productName: null,
      productTotal: null,
      accountTotal: null,
      marketEarnings: null,
      sections: [],
    } as AccountDetail)

  return {
    userId: state.user.userid,
    isLoading: state.accountDetails.isLoading,
    hasError: state.accountDetails.hasError,
    errorMessage: state.accountDetails.errorMessage,
    currentBalance: accountDetails.productTotal,
    currentAccount: getAccountByID(
      props.params.id.toString(),
      state.accounts.accounts
    ),
    sections: [
      ...accountDetails.sections
        .sort((a: { displayName: string }, b: { displayName: string }) => {
          // Order that sections should display in
          const displayNameOrder = [
            'Investment earnings',
            REINVESTED_DISTRIBUTIONS_SECTION_NAME,
            'Government contributions',
            'Employer contributions',
            'Your contributions',
            'Other',
            'Account fees & tax',
            'Account tax',
            'Withdrawals',
            'PIE tax accrued year to date',
          ]
          return (
            displayNameOrder.indexOf(a.displayName) -
            displayNameOrder.indexOf(b.displayName)
          )
        })
        .map((section, index) => {
          return {
            details: section.details.map((detail) => ({
              detailLabel: detail.displayName,
              detailTotal: detail.total,
              detailTooltip: detail.tooltip,
            })),
            label: section.displayName,
            total: section.total,
            color: state.chartColors[index],
          }
        }),
    ],
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountDetailsActions>) => ({
  getAccountDetails: bindActionCreators(AccountDetailsRequestAction, dispatch),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountPerformanceTab)
