import {
  APEX_FUNDS_CODES,
  IInvestmentOption,
  Link,
  Notification,
  ProductSlug,
} from 'shared'
import {
  RetirementProjectorAboutYouInputs,
  RetirementProjectorResponse,
  RetirementProjectorState,
  RetirementProjectorStep,
  createInitialState,
} from './RetirementProjector.types'
import { useEffect, useMemo, useState } from 'react'
import { RetirementProjectorAboutYou } from './RetirementProjectorAboutYou'
import { RetirementProjectorResults } from './RetirementProjectorResults'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import { Account } from '../../redux/accounts/accounts.model'
import { UserState } from '../../redux/user/user.model'
import { postRetirementProjection } from '../../api/advice/advice-api'

import './RetirementProjector.scss'
import { productHasStrategies } from '../../common/product-helper'
import {
  castToInvestmentOption,
  getFilteredFunds,
  getFilteredStrategies,
} from '../../utils/investmentOption'
import { AccountDetail } from '../../redux/account-details/account-details.model'

type RetirementProjectorProps = {
  account: Account
  accountDetails: AccountDetail
  user: UserState
  authToken: string
}

export const RetirementProjector = (props: RetirementProjectorProps) => {
  const { account, user, authToken, accountDetails } = props
  const [retirementProjectorStep, setRetirementProjectorStep] = useState<
    RetirementProjectorStep
  >(RetirementProjectorStep.ABOUT_YOU)

  const { contentfulEntry: contentfulProduct } = useContentfulProduct(
    account.productExternalName
  )

  const investmentOptions = useMemo(() => {
    if (!contentfulProduct) {
      return null
    }

    if (contentfulProduct.slug === ProductSlug.FFKP) {
      // For FFKP we return a combination of strategies and funds
      const options: IInvestmentOption[] = []
      if (contentfulProduct.strategies) {
        options.push(
          ...getFilteredStrategies(contentfulProduct.strategies).map((s) =>
            castToInvestmentOption(s, null)
          )
        )
      }
      if (contentfulProduct.funds) {
        options.push(
          ...getFilteredFunds(contentfulProduct.funds).map((f) =>
            castToInvestmentOption(null, f)
          )
        )
      }
      return options
    }

    return productHasStrategies(contentfulProduct.slug)
      ? getFilteredStrategies(contentfulProduct.strategies).map((s) =>
          castToInvestmentOption(s, null)
        )
      : getFilteredFunds(contentfulProduct.funds).map((f) =>
          castToInvestmentOption(null, f)
        )
  }, [contentfulProduct])

  const [projectionState, setProjectionState] = useState<
    RetirementProjectorState
  >(createInitialState(user, account))

  const handleSubmitAboutYou = (
    aboutYouInputs: RetirementProjectorAboutYouInputs
  ) => {
    setProjectionState({
      ...projectionState,
      aboutYou: aboutYouInputs,
    })
    setRetirementProjectorStep(RetirementProjectorStep.RESULTS)
  }

  const handleBackToAboutYou = () =>
    setRetirementProjectorStep(RetirementProjectorStep.ABOUT_YOU)

  useEffect(() => {
    postRetirementProjection(
      projectionState,
      'Incomplete',
      user,
      authToken
    ).then((response: RetirementProjectorResponse) => {
      setProjectionState({
        ...projectionState,
        dalId: response.dalId,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderStep = () => {
    switch (retirementProjectorStep) {
      case RetirementProjectorStep.ABOUT_YOU:
        return (
          <RetirementProjectorAboutYou
            investmentOptions={investmentOptions}
            accountDetails={accountDetails}
            currentAccount={account}
            handleSubmit={handleSubmitAboutYou}
            contentfulProduct={contentfulProduct}
            state={projectionState}
          />
        )
      case RetirementProjectorStep.RESULTS:
        return (
          <RetirementProjectorResults
            investmentOptions={investmentOptions}
            accountDetails={accountDetails}
            currentAccount={account}
            state={projectionState}
            setState={setProjectionState}
            handleBackToAboutYou={handleBackToAboutYou}
            authToken={authToken}
            contentfulProduct={contentfulProduct}
          />
        )
    }
  }

  return (
    <div className="retirement-projector__paper">
      <h6 className="mb-md color-dusk">Retirement projector</h6>
      {renderStep()}
      <Notification>
        <p className="text-bold mb-xs">Our financial advice services</p>
        <p className="mt-xs">
          Please read the{' '}
          <Link
            to="https://assets.fisherfunds.co.nz/ff-advice-disclosure"
            target="_blank"
            inline
          >
            Fisher Funds' Advice Disclosure{' '}
          </Link>{' '}
          to find out about our financial advice services
        </p>
      </Notification>
    </div>
  )
}
