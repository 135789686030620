import axios from 'axios'
import { ProductCode } from '../../common/product-variables'

const performanceApiUrl = process.env.REACT_APP_PERFORMANCE_API_URL!

export const getProductReturnsSummary = async (code: ProductCode) => {
  let response
  try {
    response = await axios.get<any>(
      `${performanceApiUrl}/returns-summary-tables`
    )
    return (response.data?.data || []).find(
      (item: { productCode: ProductCode }) => item.productCode === code
    )
  } catch (err) {
    console.log('Error:', err)
  }
}
