import { UserData } from '../redux/user/user.model'
import { useCallback } from 'react'

const websiteUrl =
  process.env.REACT_APP_WEBSITE_URL || 'https://fisherfunds.co.nz'
const isKPFPrimaryProduct =
  process.env.REACT_APP_FEATURE_ENABLE_KP_PRIMARY_PRODUCT === 'true'

const kiwiSaverJoinUrl = `${websiteUrl}/join/${
  isKPFPrimaryProduct ? 'ffkp' : 'kiwisaver'
}`
const managedFundsJoinUrl = `${websiteUrl}/join/managed-funds`

function useOpenJoinApp(user: UserData) {
  const handleChildWindowRequest = useCallback(
    (e: MessageEvent) => {
      if (
        e.origin === websiteUrl &&
        e.data === 'contactInfo' &&
        window.joiningApp
      ) {
        window.joiningApp.postMessage(
          {
            titleCode: user.titleCode,
            firstName: user.firstName,
            lastName: user.surname,
            phone: user.mobilePhoneNumber,
            email: user.email,
            dateOfBirth: user.birthDate,
          },
          websiteUrl
        )
      }
      delete window.joiningApp
      window.removeEventListener('message', handleChildWindowRequest, false)
    },
    [user]
  )

  const openManagedFundsJoinApp = useCallback(() => {
    window.addEventListener('message', handleChildWindowRequest, false)
    window.joiningApp = window.open(managedFundsJoinUrl)
  }, [handleChildWindowRequest])

  const openKiwiSaverJoinApp = useCallback(() => {
    window.addEventListener('message', handleChildWindowRequest, false)
    window.joiningApp = window.open(kiwiSaverJoinUrl)
  }, [handleChildWindowRequest])

  return {
    openManagedFundsJoinApp,
    openKiwiSaverJoinApp,
  }
}

export default useOpenJoinApp
