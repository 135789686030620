import { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import cn from 'classnames'
import Alert from 'react-s-alert'
import { Card, Fund, Notification, ProductSlug, StrategyCode } from 'shared'
import Modal, { ModalProps } from '../modal/Modal'
import { useContentfulProduct } from '../../api/contentful/useContentfulProduct'
import { AppState } from '../../redux/app-state'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import './SwitchModalPremiumService.scss'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'
import CurrencyTotal from '../currency/CurrencyTotal'
import { FundAllocation } from '../switch-contentful-modal/CustomStrategySelector/CustomStrategySelector'
import { isFundAvailable } from '../../utils/fund'
import { findFundByCode } from '../../utils/investmentOption'
import ReviewChange from '../switch-contentful-modal/ReviewChange'
import { UserData } from '../../redux/user/user.model'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import PremiumServiceContactForm from './PremiumServiceContactForm'
import { STRATEGY_SALESFORCECODE } from '../../utils/strategy'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import { FundSplit } from '../switch-contentful-modal/BuildYourOwnStrategy'
import {
  createApiDataPremiumService,
  mapUserAllocationToSwitchData,
  requestInvestmentSwitch,
} from '../switch-contentful-modal/SwitchContentfulModal.api'
import PremiumServiceBuildYourOwn from './PremiumServiceBuildYourOwn'
import { PremiumServiceIntro } from './PremiumServiceIntro'
import InvestmentSwitchNotification from '../switch-contentful-modal/InvestmentSwitchNotification'
import { PremiumServiceDownloadPdf } from './PremiumServiceDownloadPdf'
import SpinnerOnLoad from '../spinner-on-load/SpinnerOnLoad'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'
import { ProductModal } from '../modal/ProductModal'

export enum PREMIUM_SWITCH_STEP {
  INTRO = 'INTRO',
  CONTACT_FORM = 'CONTACT_FORM',
  BUILD_YOUR_OWN = 'BUILD_YOUR_OWN',
  REVIEW_SWITCH = 'REVIEW_SWITCH',
  SWITCH_PENDING = 'SWITCH_PENDING',
}

type SwitchModalPremiumServiceProps = {
  accountId: string
  onClose?: any
}

type PropsFromRedux = {
  account: Account
  accountDetails: AccountDetail
  switchNotifications?: SwitchNotification[]
  user: UserData
  authToken: string
}

const transformCustomSplitToAllocation = (
  fundSplit: FundSplit[] = [],
  productFunds: Fund[]
) =>
  fundSplit
    .filter((f) => f.ratio > 0)
    .map((f) => ({
      allocationPercentage: f.ratio,
      fund: findFundByCode(productFunds, f.id),
    }))

const SwitchModalPremiumService = ({
  account,
  accountDetails,
  accountId,
  onClose,
  switchNotifications,
  user,
  authToken,
}: SwitchModalPremiumServiceProps & PropsFromRedux) => {
  const BUILD_YOUR_OWN_ENABLED =
    process.env.REACT_APP_FEATURE_TOGGLE_PREMIUM_BUILD_YOUR_OWN === 'true'

  const [apiErrorMsg, setApiErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(PREMIUM_SWITCH_STEP.INTRO)
  const [split, setSplit] = useState<FundAllocation[]>([])
  const { contentfulEntry: contentfulProduct } = useContentfulProduct(
    account.productExternalName
  )

  const strategyFundAllocations = (contentfulProduct?.funds || [])
    .map((fund) => ({
      allocationPercentage: 0,
      fund,
    }))
    .filter(({ fund }) => isFundAvailable(fund, account?.funds))

  const allocations = useMemo(
    () =>
      transformCustomSplitToAllocation(split, contentfulProduct?.funds || []),
    [split, contentfulProduct?.funds]
  )

  const onSubmit = async () => {
    const payload = createApiDataPremiumService(
      mapUserAllocationToSwitchData(strategyFundAllocations, allocations),
      contentfulProduct,
      account
    )

    setIsLoading(true)
    await requestInvestmentSwitch(
      user.userid,
      account.accountID,
      payload,
      authToken
    )
      .then((res) => {
        if (res.data.switchStatus === 409) {
          setApiErrorMsg(
            `It appears you already have a funds switch instruction, or a transaction currently being processed. If you wish to speak to a Fisher Funds staff member about this, please get in touch with us on ${COMPANY_PHONE_NUMBER} to discuss.`
          )
        } else {
          Alert.success(
            `<h6>Thanks!</h6><p> We'll get your strategy change sorted. If you have any questions about your investment, <a href='#contact-us'>get in touch</a>.</p>`,
            { timeout: 15000 }
          )
          onClose()
        }
      })
      .catch((err: any) => {
        setApiErrorMsg(
          `Sorry, we appear to be experiencing problems processing your request. Please try again or get in touch with us on ${COMPANY_PHONE_NUMBER}.`
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const buildYourOwnAllowed =
    !!BUILD_YOUR_OWN_ENABLED &&
    (!!accountDetails?.profileCode || accountDetails?.profile?.profileCode)

  const renderContent = () => {
    if (isLoading) {
      return <SpinnerOnLoad className="my-md" isLoading centerSpinner={true} />
    }

    if (currentStep === PREMIUM_SWITCH_STEP.INTRO) {
      if (!buildYourOwnAllowed) {
        return (
          <PremiumServiceDownloadPdf
            setCurrentStep={setCurrentStep}
            onClose={onClose}
          />
        )
      }
      return <PremiumServiceIntro setCurrentStep={setCurrentStep} />
    }

    if (currentStep === PREMIUM_SWITCH_STEP.CONTACT_FORM) {
      return (
        <PremiumServiceContactForm
          onCancel={() => setCurrentStep(PREMIUM_SWITCH_STEP.INTRO)}
          account={account}
          user={user}
          authToken={authToken}
          onClose={onClose}
        />
      )
    }

    if (currentStep === PREMIUM_SWITCH_STEP.BUILD_YOUR_OWN) {
      return (
        <PremiumServiceBuildYourOwn
          split={split}
          setSplit={setSplit}
          contentfulProduct={contentfulProduct}
          onClose={onClose}
          setCurrentStep={setCurrentStep}
          strategyFundAllocations={strategyFundAllocations}
          switchNotifications={switchNotifications}
        />
      )
    }

    const customInvestmentOption = {
      shortName: 'Custom strategy',
      allocations: transformCustomSplitToAllocation(
        split,
        contentfulProduct?.funds
      ),
      isCustom: true,
      salesforceCode: STRATEGY_SALESFORCECODE.CUSTOM as StrategyCode,
    } as InvestmentOptionType

    if (currentStep === PREMIUM_SWITCH_STEP.REVIEW_SWITCH) {
      return (
        <ReviewChange
          account={account}
          user={user}
          isApexProductAccount={false}
          product={contentfulProduct}
          newInvestmentOption={customInvestmentOption}
          onCancel={() => setCurrentStep(PREMIUM_SWITCH_STEP.INTRO)}
          onSubmit={() => onSubmit()}
        />
      )
    }

    return null
  }

  const commonModalProps: Partial<ModalProps> = {
    className: cn(`switch-modal switch-premium`, {
      'switch-suspended': contentfulProduct?.isSuspended,
    }),
    subtitle: `Premium Service - ${accountId}`,
    onClose: onClose,
    open: true,
  }

  if (buildYourOwnAllowed) {
    return (
      <ProductModal
        productSlug={ProductSlug.FF_PREMIUM}
        title="Build your own investment strategy"
        {...commonModalProps}
      >
        {apiErrorMsg && (
          <Notification className="mb-sm" type="error">
            <p dangerouslySetInnerHTML={{ __html: apiErrorMsg }} />
          </Notification>
        )}
        <CurrencyTotal
          className="color-midnight mb-md"
          value={account.accountTotal}
          size="lg"
        />
        <Card>{renderContent()}</Card>
      </ProductModal>
    )
  }

  return (
    <Modal title="Change your investment options" {...commonModalProps}>
      <div className="body">
        {apiErrorMsg && (
          <Notification className="mb-sm" type="error">
            <p dangerouslySetInnerHTML={{ __html: apiErrorMsg }} />
          </Notification>
        )}
        <InvestmentSwitchNotification
          className="mt-md"
          isApexProduct={false}
          isStrategyProduct={false}
          productName={contentfulProduct?.name}
          switchNotifications={switchNotifications}
        />
        {renderContent()}
      </div>
    </Modal>
  )
}

const mapStateToProps = (
  state: AppState,
  props: SwitchModalPremiumServiceProps
) => ({
  account: getAccountByID(props.accountId, state.accounts.accounts),
  accountDetails: getAccountByID(props.accountId, state.accountDetails.accounts)
    ?.profile,
  switchNotifications: state.switchNotifications.notifications,
  user: state.user,
  authToken: state.authorisation.authorisationToken,
})

export default connect(mapStateToProps)(SwitchModalPremiumService)
