import moment from 'moment'
import Modal from '../modal/Modal'
import { Button } from 'shared'
import CustomIcon from '../../common/CustomIcon'

type SwitchPendingErrorMessageProps = {
  onClose: () => void
  lastSwitchRequest: string
  errorMessage?: string
}

const SwitchPendingErrorMessage = ({
  lastSwitchRequest,
  errorMessage,
  onClose,
}: SwitchPendingErrorMessageProps) => {
  const renderBody = () => {
    if (errorMessage) {
      return (
        <div className="flex-row py-0">
          <CustomIcon className="mr-sm" icon="error" light={true} />
          <div>
            <h6 className="switch-modal-pending-error text-bold color-midnight my-0">
              Fund change error
            </h6>
            {errorMessage && (
              <p
                dangerouslySetInnerHTML={{
                  __html: errorMessage,
                }}
              />
            )}
          </div>
        </div>
      )
    }
    return (
      <>
        <p className="text-bold">
          {`We're still processing your fund change request from ${moment(
            lastSwitchRequest
          ).format('dddd, Do MMM [at] h:mmA')}.
          `}
        </p>
        <p>
          You can only request one KiwiSaver fund change at a time. If you'd
          like to change your fund again, you'll need to wait until after it's
          completed. Fund changes take 3 working days to complete.
        </p>
        <p className="mb-xl">
          If you have any questions about your pending fund change, please email{' '}
          <a href="mailto:mailto:enquiries@fisherfunds.co.nz">
            enquiries@fisherfunds.co.nz
          </a>
          .
        </p>
      </>
    )
  }

  return (
    <Modal
      title={errorMessage ? null : 'Fund change pending'}
      open
      onClose={() => {
        if (onClose) {
          onClose()
        }
      }}
    >
      {renderBody()}
      <Button className="ml-auto" onClick={onClose}>
        Okay, got it
      </Button>
    </Modal>
  )
}

export default SwitchPendingErrorMessage
