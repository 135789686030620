import { useMemo } from 'react'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { formatPercentage, getPrettyReturns, IInvestmentOption } from 'shared'
import { getInvestmentOptionAssetMix } from '../../utils/investmentOption'
import { RiskTooltip } from '../risk-tooltip-content/RiskTooltip'
import { StrategyRiskIndicator } from '../strategy-risk-indicator/StrategyRiskIndicator'
import InvestmentMixAssets, {
  GROWTH_ASSETS_COLOR,
  INCOME_ASSETS_COLOR,
} from '../investment-mix/InvestmentMixAssets'
import { useInvestmentReturnSummary } from '../../api/returns/useInvestmentReturnSummary'
import { Product } from '../../common/product-helper'

const NO_SUGGESTED_TIMEFRAME =
  'There is no minimum suggested timeframe for this fund.'

type InvestmentOptionsDetailsProps = {
  product?: Product
  investmentOption: IInvestmentOption
}

const InvestmentOptionDetails = ({
  product,
  investmentOption,
}: InvestmentOptionsDetailsProps) => {
  const { returnsSummary } = useInvestmentReturnSummary(
    product,
    investmentOption
  )

  const prettyReturns = useMemo(() => getPrettyReturns(returnsSummary), [
    returnsSummary,
  ])

  const suggestedTimeframeLowercase = investmentOption?.suggestedTimeframe?.toLowerCase()

  const { incomeAssetsPercentage, growthAssetsPercentage } = useMemo(
    () => getInvestmentOptionAssetMix(investmentOption),
    [investmentOption]
  )

  return (
    <>
      <Tooltip
        title={
          suggestedTimeframeLowercase
            ? `Suggested minimum timeframe for this investment is ${suggestedTimeframeLowercase}.`
            : NO_SUGGESTED_TIMEFRAME
        }
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="tooltip">
          <span className="tooltip-label">Suggested minimum timeframe</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <h6 className="mb-0">
            {investmentOption.suggestedTimeframe ?? '--'}
          </h6>
        </div>
      </Tooltip>
      <hr />
      <Tooltip
        title={
          <>
            {!!prettyReturns?.inceptionDate && (
              <p>* Inception Date: {prettyReturns.inceptionDate}</p>
            )}
            {!!prettyReturns?.tooltip && <p>{prettyReturns.tooltip}</p>}
          </>
        }
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="tooltip">
          <span className="tooltip-label"> {prettyReturns?.title}</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <h6 className="mb-0">
            {prettyReturns?.value
              ? formatPercentage(prettyReturns.value, 1)
              : '--%'}
          </h6>
        </div>
      </Tooltip>
      <hr />
      {!!investmentOption.annualFees && (
        <Tooltip
          title={`
              You will be charged fees for investing in Fisher Funds investments.
              Fees are deducted from your investment and will reduce your returns.
              If Fisher Funds invests in other funds, those funds may also charge fees.
            `}
        >
          <div className="tooltip">
            <span className="tooltip-label">Annual fund charge</span>
            <span className="tooltip-icon">
              <InfoIcon />
            </span>
            <h6 className="mb-0">{investmentOption.annualFees.toFixed(2)}%</h6>
          </div>
        </Tooltip>
      )}
      <hr />
      <Tooltip
        title={
          !!investmentOption.riskLevel && (
            <>
              <RiskTooltip
                value={investmentOption.riskLevel as number}
                name={investmentOption.shortName}
                apexFundCode={investmentOption.telCode}
              />
            </>
          )
        }
        disableFocusListener
        enterTouchDelay={200}
      >
        <div className="tooltip">
          <span className="tooltip-label">Risk indicator</span>
          <span className="tooltip-icon">
            <InfoIcon />
          </span>
          <StrategyRiskIndicator
            className="risk-indicator mt-md"
            minimum={1}
            maximum={7}
            value={investmentOption.riskLevel}
          />
        </div>
      </Tooltip>
      <hr />
      <h6>Target investment mix</h6>
      {incomeAssetsPercentage !== undefined &&
        growthAssetsPercentage !== undefined && (
          <InvestmentMixAssets
            assets={[
              {
                name: 'Income Assets',
                percentage: incomeAssetsPercentage,
                color: INCOME_ASSETS_COLOR,
              },
              {
                name: 'Growth Assets',
                percentage: growthAssetsPercentage,
                color: GROWTH_ASSETS_COLOR,
              },
            ]}
          />
        )}
    </>
  )
}

export default InvestmentOptionDetails
