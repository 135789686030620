import React, { useState } from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import {
  UserActions,
  UserChangePasswordAction,
  UserChangePasswordResetAction,
} from '../../redux/user/user.actions'

import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'

import DetailsFormAlert from '../../components/details-form-alert/DetailsFormAlert'
import NewPasswordFields from '../form/new-password/NewPasswordFields'
import ContactUsModal from '../../components/contact-us-modal'

import './ChangePassword.scss'
import { useToggle } from 'shared'

// @ts-ignore
import { useForm } from 'react-hook-form'
import { Grid, Typography } from '@material-ui/core'
import { Notification } from 'shared'

export interface ChangePasswordProps {
  userid?: string
  hasPasswordChanged?: boolean
  hasError?: boolean
  isLoading?: boolean
  errorMessage?: string
  changePassword?: typeof UserChangePasswordAction
  changePasswordReset?: typeof UserChangePasswordResetAction
  nextStep?: (path: string) => void
}

export function CreatePasswordForm(props: ChangePasswordProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm()

  const [shouldResetFields, setShouldResetFields] = useState(false)

  const onSubmitEvent = (data: any) => {
    if (!data.newPassword) {
      return
    }

    props.changePassword({
      userid: props.userid,
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    })

    setShouldResetFields(true)

    setTimeout(() => {
      reset({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      })

      setShouldResetFields(false)
    }, 1350)
  }

  const ContactUsLink = () => {
    const { toggle, toggleOn, toggleOff } = useToggle(false)
    return (
      <>
        <ContactUsModal open={toggle} onClose={toggleOff} />
        <Link color="primary" underline="always" onClick={toggleOn}>
          contact us
        </Link>
      </>
    )
  }

  let currentPasswordMessage =
    errors && errors.currentPassword
      ? 'Current password is a required field.'
      : ''

  return (
    <Container maxWidth={false} className="change-password-container">
      <form
        onSubmit={handleSubmit(onSubmitEvent)}
        className="form change-password"
      >
        <Notification className="mb-md">
          Your new password needs to be comprised of a minimum of 12 characters,
          containing at least 1 uppercase letter, 1 lowercase letter and 1
          digit. Passwords that could be easily guessed or you've used in the
          past may not be accepted. If you require any assistance, please{' '}
          <ContactUsLink />.
        </Notification>

        <DetailsFormAlert
          isVisible={!props.isLoading}
          hasSucceed={props.hasPasswordChanged}
          successMessage={'Your password has been changed successfully.'}
          hasError={props.hasError}
          errorMessage={props.errorMessage}
          onClose={() => props.changePasswordReset()}
        />

        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className="fieldGroup login-field-group">
              <Typography component="p" variant="body1">
                Current password
              </Typography>
              <TextField
                type="password"
                name="currentPassword"
                id="currentPassword"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={props.isLoading}
                {...{
                  ...register('currentPassword', { required: true }),
                  ref: undefined,
                  inputRef: register('currentPassword', { required: true }).ref,
                }}
                helperText={currentPasswordMessage}
                error={!!currentPasswordMessage}
                inputProps={{
                  autoCapitalize: 'none',
                }}
              />
            </div>

            <NewPasswordFields
              disabled={props.isLoading}
              register={register}
              watch={watch}
              errors={errors}
              shouldResetFields={shouldResetFields}
            />
          </Grid>
        </Grid>

        <div className="action-buttons">
          <Button
            disableRipple
            disabled={!isDirty || props.isLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            {props.isLoading ? 'Submitting...' : 'Confirm Update'}
          </Button>
        </div>
      </form>
    </Container>
  )
}

export default connect(
  (state: AppState) => {
    return {
      userid: state.user.userid,
      hasPasswordChanged: state.user.hasPasswordChanged,
      errorMessage: state.user.errorMessage,
      hasError: state.user.hasError,
      isLoading: state.user.isLoading,
    }
  },
  (dispatch: Dispatch<UserActions>) => ({
    changePassword: bindActionCreators(UserChangePasswordAction, dispatch),
    changePasswordReset: bindActionCreators(
      UserChangePasswordResetAction,
      dispatch
    ),
    nextStep: (path: string) => dispatch(routerActions.push(path)),
  }),
  null
)(CreatePasswordForm)
