import { useMemo, useState } from 'react'
import { Button, IInvestmentOption } from 'shared'
import InvestmentOptionInfoModal from '../investment-option-info/InvestmentOptionInfoModal'
import InvestmentSwitchNotification from '../switch-contentful-modal/InvestmentSwitchNotification'
import CustomStrategySelector, {
  FundAllocation,
} from '../switch-contentful-modal/CustomStrategySelector/CustomStrategySelector'
import { connect } from 'react-redux'
import { Product } from '../../common/product-helper'
import { castToInvestmentOption } from '../../utils/investmentOption'
import { AppState } from '@auth0/auth0-react'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'
import { PREMIUM_SWITCH_STEP } from './SwitchModalPremiumService'
import { splitSums100 } from '../../common/switch-helper'

type PremiumServiceBuildYourOwnProps = {
  strategyFundAllocations: any[]
  contentfulProduct: Product
  switchNotifications?: SwitchNotification[]
  setCurrentStep: (step: PREMIUM_SWITCH_STEP) => void
  split: FundAllocation[]
  setSplit: (funds: FundAllocation[]) => void
  onClose: () => void
}

const PremiumServiceBuildYourOwn = ({
  strategyFundAllocations,
  contentfulProduct,
  switchNotifications,
  setCurrentStep,
  split,
  setSplit,
  onClose,
}: PremiumServiceBuildYourOwnProps) => {
  const [moreInfoFund, setMoreInfoFund] = useState<IInvestmentOption | null>()

  const initialFundAllocation = useMemo(() => {
    return strategyFundAllocations.map((fundAllocation) => ({
      id: fundAllocation.fund.telCode,
      name: fundAllocation.fund.shortName,
      ratio: fundAllocation.allocationPercentage || 0,
    }))
  }, [strategyFundAllocations])

  const handleMoreInfoClick = (salesforceCode: string) => {
    const fund = contentfulProduct?.funds.find(
      (f) => f.telCode === salesforceCode
    )
    if (fund) {
      setMoreInfoFund(castToInvestmentOption(null, fund))
    }
  }

  return (
    <>
      <h5>Current balance</h5>
      <CustomStrategySelector
        initialFundAllocation={initialFundAllocation}
        className="mt-lg"
        strategyFundAllocations={strategyFundAllocations}
        onChange={(funds) => setSplit(funds as FundAllocation[])}
        lite
        incrementSize={1}
        onMoreInfoClick={handleMoreInfoClick}
      />
      <div className="flex-row-justify-content-space-between mt-lg">
        <Button
          variant="link"
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          aria-controls="account-options-menu"
          aria-haspopup="true"
          onClick={() => setCurrentStep(PREMIUM_SWITCH_STEP.REVIEW_SWITCH)}
          disabled={!splitSums100(split)}
        >
          Confirm
        </Button>
      </div>
      <InvestmentSwitchNotification
        className="mt-md"
        isApexProduct={false}
        isStrategyProduct={false}
        productName={contentfulProduct?.name}
        switchNotifications={switchNotifications}
      />
      <InvestmentOptionInfoModal
        onClose={() => setMoreInfoFund(null)}
        product={contentfulProduct}
        investmentOption={moreInfoFund}
      />
    </>
  )
}

const mapStateToProps = (
  state: AppState,
  props: PremiumServiceBuildYourOwnProps
) => ({
  switchNotifications: state.switchNotifications.notifications,
})

export default connect(mapStateToProps)(PremiumServiceBuildYourOwn)
