import React, { useEffect, useState } from 'react'

import ContactUsModal from '../../components/contact-us-modal'

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'

import { ReactComponent as SpeechIcon } from '../../assets/images/icons/icon_speech.svg'
import { ReactComponent as PhoneIcon } from '../../assets/images/icons/icon_phone.svg'
import { ReactComponent as MailIcon } from '../../assets/images/icons/icon_mail.svg'

import SalesforceLiveAgent from '../salesforce-live-agent/SalesforceLiveAgent'

import { useToggle } from 'shared'

import './ContactMenu.scss'
import { COMPANY_PHONE_NUMBER } from '../contact-us-modal/ContactUsModal'

export default function ContactMenu() {
  const [buttonState, setButtonState] = useState(false)
  const [displayMenu, setDisplayMenu] = useState(false)
  const { toggle, toggleOn, toggleOff } = useToggle(false)

  useEffect(() => {
    setTimeout(() => setDisplayMenu(true), 2500)

    const clickedContactMenu = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      const contactMenu: HTMLDivElement = document.querySelector(
        '.contact-menu-component'
      ) as HTMLDivElement
      const contactMenuClickedInside =
        contactMenu.contains(target) ||
        target.classList.contains('contact-menu-icon') ||
        target.tagName === 'path'

      if (!contactMenuClickedInside) {
        setButtonState(false)
      }
    }

    // Close the contact menu on click outside
    window.addEventListener('click', clickedContactMenu)

    return () => {
      window.removeEventListener('click', clickedContactMenu)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buttonStateHandler = () => {
    setButtonState(!buttonState)
  }

  return (
    <div className={'contact-menu-component' + (displayMenu ? ' toggled' : '')}>
      <ContactUsModal open={toggle} onClose={toggleOff} />
      <button
        className={'action-button' + (buttonState ? ' toggled' : '')}
        onClick={buttonStateHandler}
      >
        {buttonState ? (
          <CloseOutlinedIcon className="contact-menu-icon" />
        ) : (
          <SpeechIcon className="contact-menu-icon" />
        )}
      </button>

      <ul className="contact-menu-list">
        <SalesforceLiveAgent />
        <li className="contact-menu-item">
          <a href="tel:0508347437">
            <span className="label">Call {COMPANY_PHONE_NUMBER}</span>
            <span className="icon">
              <PhoneIcon className="contact-menu-icon" />
            </span>
          </a>
        </li>
        <li className="contact-menu-item">
          <div
            tabIndex={0}
            role="link"
            onClick={toggleOn}
            onKeyPress={toggleOn}
          >
            <span className="label">More options</span>
            <span className="icon">
              <MailIcon className="contact-menu-icon" />
            </span>
          </div>
        </li>
      </ul>
    </div>
  )
}
