import { useMemo } from 'react'
import { IInvestmentOption, ProductSlug, StrategyFundAllocation } from 'shared'
import { showFutureSplitOnlyProduct } from '../../common/switch-helper'
import InvestmentMixAssets, {
  Asset,
} from '../investment-mix/InvestmentMixAssets'
import { Product } from '../../common/product-helper'

const mapAllocationToAsset = ({
  fund,
  allocationPercentage,
}: StrategyFundAllocation): Asset => ({
  name: fund.shortName,
  percentage: allocationPercentage,
})

export type CustomInvestmentProps = {
  isCustom?: boolean
  allocations: StrategyFundAllocation[]
  futureAllocations?: StrategyFundAllocation[]
}

export type CustomInvestmentOptionDetailsProps = {
  investmentOption: CustomInvestmentProps | IInvestmentOption
  isCurrent?: boolean
  product: Product
}

const CustomInvestmentOptionDetails = ({
  investmentOption,
  isCurrent,
  product,
}: CustomInvestmentOptionDetailsProps) => {
  const {
    allocations,
    futureAllocations,
  } = investmentOption as CustomInvestmentProps
  const isKPAccount = useMemo(() => product.slug === ProductSlug.FFKP, [
    product,
  ])
  const isIFAccount = useMemo(() => product.slug === ProductSlug.FFIF, [
    product,
  ])
  const showCurrentAllocations = useMemo(
    () => !showFutureSplitOnlyProduct(product),
    [product]
  )

  const currentLabel = useMemo(() => {
    if (isCurrent) {
      return isKPAccount ? 'Your current mix of funds' : 'Your current strategy'
    }
    return isKPAccount ? 'Your new mix of funds' : 'Your new custom strategy'
  }, [isCurrent, isKPAccount])

  const hasFutureAllocations = futureAllocations?.length
  const multipleAllocations =
    allocations?.length > 1 || futureAllocations?.length > 1

  return (
    <div className="allocation-details">
      {showCurrentAllocations && (
        <>
          <p
            className="mt-0 text-large text-bold"
            data-test="current-allocations"
          >
            {currentLabel}
          </p>
          {!!allocations?.length && (
            <>
              <p className="color-text-light">Current Balance</p>
              <InvestmentMixAssets
                assets={allocations.map(mapAllocationToAsset)}
                showLineGraph={multipleAllocations}
              />
            </>
          )}
        </>
      )}
      {!!hasFutureAllocations && (
        <>
          {isIFAccount ? (
            <p data-test="future-allocations">
              Your {isCurrent ? 'current' : 'new'} mix of funds
            </p>
          ) : (
            <p className="color-text-light" data-test="future-allocations">
              Future Investments
            </p>
          )}
          <InvestmentMixAssets
            assets={futureAllocations.map(mapAllocationToAsset)}
            showLineGraph={multipleAllocations}
          />
        </>
      )}
    </div>
  )
}

export default CustomInvestmentOptionDetails
