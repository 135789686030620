import { Dispatch, connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import {
  canDisplayFirstHomeBuyerTools,
  canDisplayRetirementTool,
} from '../../common/accounts-helper'

import NavigationDesktop from './NavigationDesktop'
import { LayoutToggleSidebar } from '../../redux/layout/layout.actions'
import { bindActionCreators } from 'redux'

const mapStateToProps = (state: AppState) => ({
  showInvestmentTools:
    canDisplayRetirementTool(state.accounts.accounts, state.user) ||
    canDisplayFirstHomeBuyerTools(state.accounts.accounts),
  showSidebar: state.layout.showSidebar,
  activeMenu: state.layout.activeMenu,
})

// Can probably be deleted
const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  toggleSidebar: bindActionCreators(LayoutToggleSidebar, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavigationDesktop)
