import Modal from '../modal/Modal'
import { IInvestmentOption } from 'shared'
import InvestmentOptionInfoDetail from './InvestmentOptionInfoDetail'
import { Product } from '../../common/product-helper'
import { InvestmentOptionType } from '../compare-investment-options/InvestmentOption'
import { ProductSlug } from '../../common/product-variables'

type SingleInvestmentOptionInfoModalProps = {
  onClose: () => void
  investmentOption: InvestmentOptionType
  product: Product
}

const getStrategyName = (investmentOption: IInvestmentOption): string => {
  return investmentOption.strategyFundAllocations?.length === 1
    ? investmentOption.shortName.replace('Strategy', 'Fund')
    : investmentOption.shortName
}

const SingleInvestmentOptionInfoModal = (
  props: SingleInvestmentOptionInfoModalProps
) => {
  const { investmentOption, product } = props

  const showPercentageFundAllocated =
    [ProductSlug.FF_TWO, ProductSlug.FFMF].includes(product.slug) &&
    !investmentOption.isCustom &&
    investmentOption.strategy?.strategyFundAllocations.length &&
    !investmentOption.strategy?.ageRelatedStrategy

  const renderFundAllocations = () => {
    if (!showPercentageFundAllocated) {
      return null
    }

    return (
      <>
        {investmentOption.strategy.strategyFundAllocations?.map((s) => (
          <p className="color-text-light my-0" key={s.fund.name}>
            {s.allocationPercentage}% {s.fund.shortName}
          </p>
        ))}
      </>
    )
  }

  return (
    <Modal
      title={getStrategyName(investmentOption)}
      subtitle={renderFundAllocations()}
      open
      className="switch-sub-modal"
      onClose={props.onClose}
    >
      <InvestmentOptionInfoDetail
        investmentOption={investmentOption}
        product={product}
      />
    </Modal>
  )
}

export default SingleInvestmentOptionInfoModal
