import { ReactNode, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import './modal.scss'

export type ModalProps = {
  open?: boolean
  onClose?: () => void
  title: string
  subtitle?: string | React.ReactNode
  className?: string
  fullScreen?: boolean
  contentRef?: React.RefObject<ReactNode>
}

const resetHtmlAndBodyStyles = () => {
  document.documentElement.style.overflow = null
  document.body.style.overflow = null
}

// Fix for iframe scroller inside a popup modal
const handleIframeResize = () => {
  setTimeout(() => {
    const iframe: HTMLDivElement = document.querySelector(
      '.material-modal .iframe'
    ) as HTMLDivElement

    if (!iframe) {
      return
    }

    const modalContent: HTMLDivElement = document.querySelector(
      '.material-modal .content'
    ) as HTMLDivElement

    iframe.style.height = null
    modalContent.style.overflow = null

    resetHtmlAndBodyStyles()

    iframe.style.height = modalContent.offsetHeight + 'px'

    setTimeout(() => {
      modalContent.style.overflow = 'hidden'

      // Exclude iPhone 4 and 5
      if (window.outerHeight <= 320 || window.outerWidth <= 320) {
        modalContent.style.overflow = null
      }
    }, 100)
  }, 300)
}

const Modal = (props: React.PropsWithChildren<ModalProps>) => {
  const { fullScreen = true, className, open, contentRef } = props

  // This shouldn't be here and instead of using <Modal> <Iframe> we should use <IframeModal>
  useEffect(() => {
    handleIframeResize()
    window.addEventListener('resize', handleIframeResize, false)
    return () => {
      resetHtmlAndBodyStyles()
      window.removeEventListener('resize', handleIframeResize)
    }
  })

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={cn('material-modal', {
        [`${className}`]: !!className,
      })}
      maxWidth={false}
      open={open}
      onClose={props.onClose}
      onEscapeKeyDown={props.onClose}
    >
      <div className="modal-background"></div>
      <div className="modal-body">
        {(props.title || props.subtitle) && (
          <div className="header flex-row">
            <div className="flex-column">
              <h4 className="mb-0">{props.title}</h4>
              {!!props.subtitle && (
                <p className="modal-subtitle text-large my-0">
                  {props.subtitle}
                </p>
              )}
            </div>
            <IconButton
              aria-label="close"
              className="close-button ml-auto"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}

        <MuiDialogContent className={'content'} ref={contentRef}>
          {props.children}
        </MuiDialogContent>
      </div>
    </Dialog>
  )
}

export default Modal
