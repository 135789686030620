import './Currency.scss'
import { ReactComponent as IconTrendingUp } from '../../assets/images/icons/icon_trending_up.svg'
import { ReactComponent as IconTrendingDown } from '../../assets/images/icons/icon_trending_down.svg'
import { formatSignedCurrency } from 'shared'

interface CurrencyDifferenceProps {
  value: number
  format?: string
}

const getColorClass = (value: number) => {
  return value < 0 ? 'currency-negative' : 'currency-positive'
}

export default function CurrencyDifference(props: CurrencyDifferenceProps) {
  const { value, format = '$0,0.00' } = props
  const Icon = value >= 0 ? IconTrendingUp : IconTrendingDown
  return (
    <span className={`currency ${getColorClass(value)}`}>
      {formatSignedCurrency(value, format)}
      <Icon className="currency__trend-icon" width="18px" />
    </span>
  )
}
