import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiLink from '@material-ui/core/Link'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Button } from 'shared'
import { HASH_MODALS } from '../../common/hash-helper'

export default function AccountDetailsMenuDepositMode() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleShowAccountOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="action-buttons">
      <Button
        variant="outlined"
        color="white"
        iconLeft={MoreHorizIcon}
        aria-controls="account-options-menu"
        aria-haspopup="true"
        onClick={handleShowAccountOptions}
      >
        <span className="desktop">Account options</span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id="account-options-menu"
        className="account-options-menu deposit-mode"
      >
        <MenuItem disableRipple>
          <MuiLink
            href={`#${HASH_MODALS.ADD_FUNDS}`}
            className="text-decoration-none color-dusk"
            onClick={handleClose}
          >
            Add funds
          </MuiLink>
        </MenuItem>
      </Menu>
    </div>
  )
}
