import Joi from 'joi'
import { isValidNZBankNumber } from 'shared'
import { WithdrawalTypeEnum } from './WithdrawFundsModalGeneric'

export const withdrawalFormSchema = (accountTotalMinus100: number) => {
  return Joi.object({
    product: Joi.string().required(),
    accountId: Joi.string().required(),
    withdrawalType: Joi.string().required(),
    withdrawalAmount: Joi.when('withdrawalType', {
      is: WithdrawalTypeEnum.Partial,
      then: Joi.number()
        .min(0.01)
        .max(accountTotalMinus100)
        .messages({
          'number.empty': 'Withdrawal amount is required',
          'any.required': 'Withdrawal amount is required',
          'number.base': 'Withdrawal amount is required',
          'number.min': 'Withdrawal amount must be greater than $0',
          'number.max': `Your withdrawal amount must be less than $${accountTotalMinus100}`,
          'number.unsafe': `Your withdrawal amount must be less than $${accountTotalMinus100}`,
        })
        .when('product', {
          is: 'FFKP',
          then: Joi.number().min(100).required().messages({
            'number.min': 'Withdrawal amount must be at least $100',
          }),
        })
        .when('product', {
          is: 'FFTWO',
          then: Joi.number().min(500).required().messages({
            'number.min': 'Withdrawal amount must be at least $500',
          }),
        }),
      otherwise: Joi.any().valid(null, ''),
    }),
    withdrawalFunds: Joi.when('withdrawalType', {
      is: WithdrawalTypeEnum.Partial,
      then: Joi.array().items(
        Joi.object({
          assetCode: Joi.string().required(),
          amount: Joi.number().integer().min(0).required(),
        })
      ),
    }),
    useExistingBankAccount: Joi.boolean().required(),
    bankAccountName: Joi.when('useExistingBankAccount', {
      is: false,
      then: Joi.string().required().messages({
        'string.base': ' Your account name is required',
        'string.empty': 'Your account name is required',
        'any.required': 'Your account name is required',
      }),
    }),
    bankAccountNumber: Joi.when('useExistingBankAccount', {
      is: false,
      then: Joi.string()
        .custom((value, helpers) => {
          const isValid = isValidNZBankNumber(value)
          if (!isValid) {
            return helpers.error('any.invalid')
          }

          return value
        })
        .messages({
          'string.base': ' Your account number is required',
          'string.empty': 'Your account number is required',
          'string.required': 'Your account number is required',
          'any.required': 'Your account number is required',
          'any.invalid':
            'Please enter a valid New Zealand bank account number.',
        }),
    }),
  })
}
