import { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { Dispatch, connect } from 'react-redux'
import { Link } from 'react-router'
import { Button, Link as SharedLink } from 'shared'
import { AppState } from '../../redux/app-state'
import RetirementCalculatorLink from '../../components/retirement-calculator-link/RetirementCalculatorLink'
import FirstTimeBuyerLink from '../../components/first-time-buyer-link/FirstTimeBuyerLink'
import {
  PRODUCTS_WITH_RETIREMENT_PROJECTOR,
  canDisplayFirstHomeBuyerTools,
  canDisplayRetirementTool,
  getFirstHomeCalculatorUrl,
  getKiwisaverAccount,
  isApexProductAccount,
  isKiwiSaverTwoAccount,
} from '../../common/accounts-helper'
import FirstHomeStepsModal from '../../components/first-home-steps-modal'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import './InvestmentTool.scss'
import { Account } from '../../redux/accounts/accounts.model'
import { useLiveChatContext } from '../../components/live-chat/useLiveChatContext'

export interface InvestmentToolProps {
  showRetirementTool: boolean
  showFirstHomeBuyerTools: boolean
  ownKiwiSaver?: Account
  setActiveAccountTab: (value: string) => void
  setActiveMenu: (activeMenu: string) => void
}

export function InvestmentTool(props: InvestmentToolProps) {
  const { showRetirementTool, showFirstHomeBuyerTools, ownKiwiSaver } = props
  const [modalOpen, setModalOpen] = useState(false)

  const {
    isLoaded: isLiveChatLoaded,
    open: openLiveChat,
  } = useLiveChatContext()

  useEffect(() => {
    props.setActiveMenu('investment')
    props.setActiveAccountTab('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // First Home Buyers Letter won't be available for FFTWO clients at the beginning of the Apex migration
  const isFHBLetterAndCalcAvailable =
    ownKiwiSaver &&
    (!isKiwiSaverTwoAccount(ownKiwiSaver, false) ||
      !isApexProductAccount(ownKiwiSaver?.productExternalRef))

  const renderRetirementProjectorLink = () => {
    const showNewRetirementProjector =
      ownKiwiSaver &&
      !!PRODUCTS_WITH_RETIREMENT_PROJECTOR(ownKiwiSaver) &&
      !!ownKiwiSaver.accountID

    if (showNewRetirementProjector) {
      return (
        <Link
          className="investment-tool-button link--button link link--filled link--primary link--md"
          to={`/investment-tools/retirement-projector?accountId=${ownKiwiSaver?.accountID}`}
        >
          Calculate my retirement
        </Link>
      )
    }

    return (
      <RetirementCalculatorLink
        activeLinkId="retirement-calculator"
        buttonColor="primary"
        buttonVariant="contained"
        popupTitle="Retirement projector"
      />
    )
  }

  const renderFirstHomeBuyerCalcTile = () => {
    return (
      <div className="investment-tool-card">
        <h5>First Home Buyer</h5>
        <p className="color-text-dark">
          See how your KiwiSaver account can help you buy your first home.
        </p>
        <div className="mt-lg">
          {isFHBLetterAndCalcAvailable ? (
            <FirstTimeBuyerLink
              activeLinkId="first-time-buyer"
              buttonColor="primary"
              buttonVariant="contained"
              popupTitle="First home calculator"
            />
          ) : (
            <SharedLink
              className={'investment-tool-button'}
              variant={'filled'}
              target="_blank"
              rel="noreferrer noopener"
              to={ownKiwiSaver && getFirstHomeCalculatorUrl(ownKiwiSaver)}
            >
              First home calculator
            </SharedLink>
          )}
        </div>
      </div>
    )
  }

  const renderFirstHomeBuyerLetterTile = () => {
    return (
      <div className="investment-tool-card">
        <h5>First Home Buyer's Letter</h5>
        {isFHBLetterAndCalcAvailable ? (
          <>
            <p className="color-text-dark">
              Download a copy of your first home buyer's letter which includes
              how much you may be eligible to withdraw from your KiwiSaver
              account for your first home purchase.
            </p>
            <div className="mt-lg">
              <Button
                color="primary"
                variant="filled"
                onClick={() => setModalOpen(true)}
              >
                Get your letter
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="color-text-dark">
              Please message us to request a First Home Buyer's letter, which
              includes how much you may be able eligible to withdraw from your
              KiwiSaver account for your first home purchase.
            </p>
            {isLiveChatLoaded && (
              <div className="mt-lg">
                <Button
                  color="primary"
                  variant="filled"
                  onClick={() => openLiveChat()}
                >
                  Request your letter
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    )
  }

  return (
    <div className="investment-tools-container main-content-padding">
      <div className="grid-container">
        {showRetirementTool && (
          <div className="investment-tool-card">
            <h5>Retirement Projector</h5>
            <p className="color-text-dark">
              Check if you are on track to achieve your desired retirement
              lifestyle.
            </p>
            <div className="mt-lg">{renderRetirementProjectorLink()}</div>
          </div>
        )}

        {showFirstHomeBuyerTools && (
          <>
            {renderFirstHomeBuyerCalcTile()}
            {modalOpen && (
              <FirstHomeStepsModal onClose={() => setModalOpen(false)} />
            )}

            {renderFirstHomeBuyerLetterTile()}
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  showRetirementTool: canDisplayRetirementTool(
    state.accounts.accounts,
    state.user
  ),
  showFirstHomeBuyerTools: canDisplayFirstHomeBuyerTools(
    state.accounts.accounts
  ),
  ownKiwiSaver: getKiwisaverAccount(state.accounts.accounts),
})

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentTool)
