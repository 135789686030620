import Modal from '../modal/Modal'
import { Account } from '../../redux/accounts/accounts.model'
import {
  getAccountProductSlug,
  getProductPdsUrl,
  productHasStrategies,
} from '../../common/product-helper'
import { isManagedFundProductAccount } from '../../common/accounts-helper'
import { ProductSlug } from '../../common/product-variables'

export type TermsModalProps = {
  onClose: () => void
  account: Account
  showRebalanceTerm?: boolean
}

const TermsModalApex = (props: TermsModalProps) => {
  const hasStrategies = productHasStrategies(
    getAccountProductSlug(props.account) as ProductSlug
  )
  const investmentTerm = hasStrategies ? 'strategy' : 'fund(s)'
  const isMF = isManagedFundProductAccount(props.account)

  return (
    <Modal
      title="Terms and conditions"
      open
      className="switch-sub-modal"
      onClose={props.onClose}
    >
      <div>
        <p>
          I hereby request Fisher Funds to direct future investments and/or
          change my current balance in accordance with my instructions in this
          form and declare that:
        </p>
        <ol>
          <li>
            {isMF
              ? 'I am authorised (including where an account has more than one account holder, by all account holders) to make investment decisions for this account.'
              : 'I am authorised to make investment decisions for this account.'}
          </li>
          <li>
            I have read the current{' '}
            <a
              href={getProductPdsUrl(props.account)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.account.productExternalName} Product Disclosure Statement
            </a>
            .
          </li>
          <li>
            I understand that it may take up to three business days for you to
            complete changing my {investmentTerm}.
          </li>
          <li>
            I understand that changing my current balance to a different{' '}
            {investmentTerm} requires you to "sell" investments from my current{' '}
            {investmentTerm} and "buy" investments in my chosen {investmentTerm}{' '}
            and that any fees or taxes may be deducted from my account.
          </li>
          {props.showRebalanceTerm && (
            <li>
              If I am investing in the Balanced Strategy I authorise Fisher
              Funds to rebalance my target fund mix to 40% Conservative Fund and
              60% Growth Fund on an annual basis.
            </li>
          )}
          {isMF && (
            <li>
              I understand that if I change from the Balanced Strategy to a
              Custom Strategy my account will not be rebalanced on annual basis.
            </li>
          )}
        </ol>
      </div>
    </Modal>
  )
}

export default TermsModalApex
