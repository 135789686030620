import { APEX_FUNDS_CODES, IInvestmentOption } from 'shared'
import {
  castToInvestmentOption,
  getEquivalentFundCode,
} from '../../utils/investmentOption'
import SingleInvestmentOptionInfoModal from './SingleInvestmentOptionInfoModal'
import DualInvestmentOptionInfoModal from './DualInvestmentOptionInfoModal'
import { Product } from '../../common/product-helper'

type InvestmentOptionInfoModalProps = {
  onClose: () => void
  investmentOption: IInvestmentOption
  product: Product
}

const InvestmentOptionInfoModal = (props: InvestmentOptionInfoModalProps) => {
  const { onClose, investmentOption, product } = props

  return (
    <>
      {!!investmentOption &&
        (investmentOption.telCode === APEX_FUNDS_CODES.FFKP_BALANCED ||
        investmentOption.telCode === APEX_FUNDS_CODES.FFKP_DEFAULT ? (
          <DualInvestmentOptionInfoModal
            open
            onClose={() => onClose()}
            investmentOptions={[
              investmentOption,
              castToInvestmentOption(
                null,
                product.funds.find(
                  (f) =>
                    f.telCode ===
                    getEquivalentFundCode(investmentOption.telCode)
                )
              ),
            ]}
            product={product}
            className="switch-sub-modal"
          />
        ) : (
          <SingleInvestmentOptionInfoModal
            investmentOption={investmentOption}
            onClose={() => onClose()}
            product={product}
          />
        ))}
    </>
  )
}

export default InvestmentOptionInfoModal
