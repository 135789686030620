import { useEffect, useState } from 'react'
import { IInvestmentOption, ReturnsSummaryItem } from 'shared'
import { getProductReturnsSummary } from '.'
import { Product } from '../../common/product-helper'
import { getReturnByDisplayName } from '../../common/returns-helper'

export const useInvestmentReturnSummary = (
  product: Product,
  investmentOption: IInvestmentOption
) => {
  const [returnsSummary, setReturnsSummary] = useState<
    ReturnsSummaryItem | undefined
  >()
  useEffect(() => {
    getProductReturnsSummary(product.productCode).then((result: any) => {
      if (!result?.items) {
        return
      }
      const resultItems: ReturnsSummaryItem[] = result.items

      setReturnsSummary(() => {
        const found =
          investmentOption.telCode &&
          resultItems.find((i) => i.fundCode === investmentOption.telCode)
        if (found) {
          return found
        }

        // Strategies doesn't have a registry code so we match them by shortName.
        return getReturnByDisplayName(
          resultItems,
          product.productCode,
          investmentOption.shortName
        )
      })
    })
  }, [product, investmentOption])
  return {
    returnsSummary,
  }
}
