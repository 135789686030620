import cn from 'classnames'
import './RiskTooltip.scss'
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go'
import { APEX_FUNDS_CODES } from 'shared'

export type RiskTooltipProps = {
  value: number
  name: string
  apexFundCode?: string
}

export const RiskChart = ({
  value,
  theme = 'dark',
}: {
  value: number
  theme?: 'dark' | 'light'
}) => {
  const numbers = Array.from(Array(7).keys()).map((i) => i + 1)
  return (
    <span
      className={cn('risk-chart-container', {
        'risk-chart--light': theme === 'light',
      })}
    >
      <span className="risk-chart__risk-labels">
        <span className="risk-chart__text">
          <span className="risk-chart__text--bold"> Lower </span>
          risk
        </span>
        <span className="risk-chart__text--right">
          <span className="risk-chart__text--bold"> Higher </span>
          risk
        </span>
      </span>
      <span className="risk-chart__chart-container">
        {numbers.map((num) => (
          <div
            key={num}
            className={
              value === num ? 'risk-chart__value' : 'risk-chart__option'
            }
          >
            {value === num && (
              <GoTriangleDown size="1rem" className="risk-chart__arrow-down" />
            )}
            {num}
            {value === num && (
              <GoTriangleUp size="1rem" className="risk-chart__arrow-up" />
            )}
          </div>
        ))}
      </span>
      <span className="risk-chart__return-labels">
        <span className="risk-chart__text">
          Potential
          <span className="risk-chart__text--bold"> lower </span>
          returns
        </span>
        <span className="risk-chart__text--right">
          Potential
          <span className="risk-chart__text--bold"> higher </span>
          returns
        </span>
      </span>
    </span>
  )
}

const riskIndicatorAdditionalCopyFFKP = {
  [APEX_FUNDS_CODES.FFKP_AGGRESSIVE]: `Market index returns have been used to fill in the risk indicator as this Fund has not been in operation for five years. 
  Market index returns have been used for the entire five year period from 1 January 2020. 
  As a result of those returns being used, the risk indicator may provide a less reliable indicator of the potential future volatility of the fund.`,
  [APEX_FUNDS_CODES.FFKP_DEFAULT]: `Market index returns as well as the fund’s actual returns have been used to fill in the risk indicator, 
  as this fund has not been in operation for 5 years. 
  Market Index returns have been used from 1 January 2020 to 30 November 2021 and actual returns after that. 
  As a result of those returns being used, the risk indicator may provide a less reliable indicator of the potential future volatility of the fund.`,
}

export const RiskTooltip = ({
  value,
  name,
  apexFundCode,
}: RiskTooltipProps) => {
  const additionalDescription =
    apexFundCode && riskIndicatorAdditionalCopyFFKP[apexFundCode]
  return (
    <div className="risk-tooltip__container">
      <p className="risk-tooltip__title">{`Risk indicator for the ${name}:`}</p>

      <RiskChart value={value} />
      <p className="risk-tooltip__description">
        The risk indicator is rated from 1 (low) to 7 (high). The rating
        reflects how much the value of the fund’s assets goes up and down
        (volatility). A higher risk generally means higher potential returns
        over time, but more ups and downs along the way.
      </p>
      {additionalDescription && (
        <p className="risk-tooltip__description">{additionalDescription}</p>
      )}
    </div>
  )
}
