import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { Tooltip } from '@material-ui/core'
import './Slider.scss'

type SliderProps = {
  value: number
  min: number
  max: number
  step?: number
  current?: number
  onChange: (value: number) => void
  className?: string
  tooltipClassName?: string
}

export default function Slider({
  value,
  min,
  max,
  step = 1,
  current = 0,
  onChange,
  className,
  tooltipClassName,
}: SliderProps) {
  const [railLength, setRailLength] = useState(0)
  const [railOffset, setRailOffset] = useState(0)
  const [thumbOffset, setThumbOffset] = useState(0)
  const [draggingThumb, setDraggingThumb] = useState(false)
  const [sliderRailRef, setSliderRailRef] = useState(null)

  const handleResize = () => {
    if (sliderRailRef) {
      setRailLength(sliderRailRef.clientWidth)
      const viewportOffset = sliderRailRef.getBoundingClientRect()
      setRailOffset(viewportOffset.left)
      setThumbOffset(sliderRailRef.clientWidth * (value / (max - min)))
    }
  }

  const currentOffset = useMemo(() => {
    if (sliderRailRef) {
      return sliderRailRef.clientWidth * (current / (max - min))
    }
    return 0
  }, [current, sliderRailRef, min, max])

  const persistedHandleResize = useCallback(handleResize, [
    sliderRailRef,
    value,
    min,
    max,
  ])

  // re render when window size change
  useEffect(() => {
    window.addEventListener('resize', persistedHandleResize)
    return () => {
      window.removeEventListener('resize', persistedHandleResize)
    }
  }, [persistedHandleResize])

  useEffect(() => {
    persistedHandleResize()
  }, [persistedHandleResize])

  // init slider
  useEffect(() => {
    setThumbOffset(railLength * (value / (max - min)))
  }, [value, min, max, railLength])

  const calcThumbOffset = (mouseOffset: number) => {
    if (mouseOffset > railOffset && mouseOffset < railOffset + railLength) {
      const newValue = max * ((mouseOffset - railOffset) / railLength)
      const remainder = newValue % step
      const multiplier = Math.floor(newValue / step)
      if (remainder >= step / 2) {
        const closestValue = step * (multiplier + 1)
        onChange(closestValue <= max ? closestValue : max)
      } else {
        const closestValue = step * multiplier
        onChange(closestValue >= min ? closestValue : min)
      }
    } else if (mouseOffset >= railOffset + railLength && value < max) {
      onChange(max)
    } else if (mouseOffset <= railOffset && value > min) {
      onChange(min)
    }
  }

  const handleMouseMove = (e: MouseEventInit) => {
    const event = e as MouseEvent
    calcThumbOffset(event.clientX)
  }

  const handleMouseUp = () => {
    setDraggingThumb(false)
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e.target) {
      // move thumb to the position where cursor is when in range
      calcThumbOffset(e.clientX)

      setDraggingThumb(true)
      window.addEventListener('mousemove', handleMouseMove, false)
      window.addEventListener('mouseup', handleMouseUp, false)
    }
  }

  const handleTouchMove = (e: TouchEventInit) => {
    if (e.touches) {
      calcThumbOffset(e.touches[0].clientX)
    }
  }

  const handleTouchEnd = () => {
    setDraggingThumb(false)
    window.removeEventListener('touchmove', handleTouchMove)
    window.removeEventListener('touchend', handleTouchEnd)
  }

  const handleTouchStart = (e: React.TouchEvent<HTMLElement>) => {
    // move thumb to the position where cursor is when in range
    calcThumbOffset(e.touches[0].clientX)

    setDraggingThumb(true)
    window.addEventListener('touchmove', handleTouchMove, false)
    window.addEventListener('touchend', handleTouchEnd, false)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={cn('slider-v2', { [`${className}`]: !!className })}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <span
        className="slider-v2__rail"
        ref={(r) => {
          if (!!r) {
            setSliderRailRef(r)
          }
        }}
      />
      <span
        className="slider-v2__track"
        style={{ width: `${thumbOffset}px` }}
      />
      <Tooltip
        title={`${value}%`}
        arrow
        placement="top"
        disableFocusListener
        enterDelay={200}
        enterTouchDelay={200}
        leaveDelay={0}
        PopperProps={{
          className: cn(
            'MuiTooltip-popper MuiTooltip-popperArrow slider-v2__tooltip',
            {
              [`${tooltipClassName}`]: !!tooltipClassName,
            }
          ),
        }}
      >
        <span
          className={cn('slider-v2__thumb', {
            'slider-v2__thumb--active': draggingThumb,
          })}
          style={{ left: `${thumbOffset}px` }}
        />
      </Tooltip>
      <Tooltip
        title={`Current allocation (${current}%)`}
        arrow
        placement="top"
        disableFocusListener
        enterDelay={200}
        enterTouchDelay={200}
        leaveDelay={0}
        PopperProps={{
          className: cn(
            'MuiTooltip-popper MuiTooltip-popperArrow slider-v2__tooltip',
            {
              [`${tooltipClassName}`]: !!tooltipClassName,
            }
          ),
        }}
      >
        <span
          className="slider-v2__current"
          style={{ left: `${currentOffset}px` }}
        />
      </Tooltip>
    </span>
  )
}
