import { useMemo, useState } from 'react'
import cn from 'classnames'
import numeral from 'numeral'
import { useFormik } from 'formik'
import {
  Button,
  Card,
  ControlledAmountInput,
  ControlledRadioMenu,
  schemaValidation,
  Switch,
} from 'shared'
import {
  WithdrawalFormFields,
  WithdrawalTypeEnum,
} from '../WithdrawFundsModalGeneric'
import { Account } from '../../../redux/accounts/accounts.model'
import { withdrawalFormSchema } from '../WithdrawFundsForm.schema'
import './MFWithdrawForm.scss'
import CurrencyTotal from '../../currency/CurrencyTotal'

type MFWithdrawFormProps = {
  account: Account
  onCancel: () => void
  onSubmit: (values: WithdrawalFormFields) => void
  isDisable?: boolean
}

const MINIMUM_ACCOUNT_BALANCE = 1000

export const MFWithdrawForm = ({
  account,
  onCancel,
  onSubmit,
  isDisable = false,
}: MFWithdrawFormProps) => {
  const [showFundSelector, setShowFundSelector] = useState(false)

  const initialValues: WithdrawalFormFields = {
    product: account.productExternalRef,
    accountId: account.accountID,
    withdrawalType: WithdrawalTypeEnum.Partial,
    withdrawalAmount: null,
    withdrawalFunds: null,
    useExistingBankAccount: true,
    bankAccountName: null,
    bankAccountNumber: null,
  }

  const accountTotalMinusMinimumBalance = useMemo(() => {
    return numeral(account?.accountTotal)
      .subtract(MINIMUM_ACCOUNT_BALANCE)
      .value()
  }, [account])

  const form = useFormik({
    initialValues: { ...initialValues },
    validate: schemaValidation<WithdrawalFormFields>(
      withdrawalFormSchema(accountTotalMinusMinimumBalance)
    ),
    onSubmit: () => {
      if (form.values.withdrawalType === WithdrawalTypeEnum.Full) {
        form.setFieldValue('withdrawalAmount', null)
        form.setFieldValue('withdrawalFunds', null)
      }
      if (form.values.useExistingBankAccount) {
        form.setFieldValue('bankAccountName', null)
        form.setFieldValue('bankAccountNumber', null)
      }
      onSubmit(form.values)
    },
    validateOnMount: true,
  })

  const isPartialWithdrawal =
    form.values.withdrawalType === WithdrawalTypeEnum.Partial

  return (
    <form
      className="mf-withdraw-form"
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <Card>
        <ControlledRadioMenu
          direction="vertical"
          form={form}
          showErrorLabel={false}
          options={[
            {
              label: 'Partial Withdrawal',
              value: WithdrawalTypeEnum.Partial,
              subLabel: (
                <>
                  {isPartialWithdrawal && (
                    <>
                      <label htmlFor="partial-withdraw-amount">
                        Enter amount
                      </label>
                      <div
                        className={cn('input-group mt-xs', {
                          'input-group--disabled': showFundSelector,
                        })}
                      >
                        <ControlledAmountInput
                          className="input-amount--dollar-sign partial-withdraw-amount text-bold"
                          id="partial-withdraw-amount"
                          form={form}
                          name="withdrawalAmount"
                          labelSize="md"
                          disabled={showFundSelector}
                          showErrorLabel={false}
                        />
                        {(account.funds || []).length > 1 && (
                          <Switch
                            className="switch-funds-selector text-small p-2xs"
                            label="Specify the fund"
                            id="toggle-funds-selector"
                            onChange={() =>
                              setShowFundSelector(!showFundSelector)
                            }
                          />
                        )}
                      </div>
                    </>
                  )}
                  {showFundSelector && (
                    <ul className="fund-selector">
                      {(account.funds || []).map((fund, index) => (
                        <li className="flex-row" key={fund.assetCode}>
                          <div className="flex-column">
                            <span className="font-fund">
                              {fund.fundDisplayName}
                            </span>
                            <em className="color-text-dark">
                              <CurrencyTotal
                                className="text-small"
                                value={fund.dollarBalance}
                              />
                              <span className="ml-2xs">available</span>
                            </em>
                          </div>
                          <ControlledAmountInput
                            className="input-amount--dollar-sign ml-auto"
                            form={form}
                            name={`withdrawalFunds[${index}]`}
                            showErrorLabel={false}
                            max={fund.dollarBalance}
                            onMax={() =>
                              form.setFieldValue(
                                `withdrawalFunds[${index}]`,
                                fund.dollarBalance
                              )
                            }
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                  {isPartialWithdrawal && (
                    <em className="mt-xs mb-md text-small text-thin">
                      Note: to keep your account open you must leave a minimum
                      of ${MINIMUM_ACCOUNT_BALANCE} in your account.
                    </em>
                  )}
                  <hr className="mt-0" />
                </>
              ),
            },
            {
              label: 'Withdraw my entire investment',
              value: WithdrawalTypeEnum.Full,
              subLabel: !isPartialWithdrawal && (
                <div>
                  <p className="text-small text-thin ml-xl">
                    <em>${accountTotalMinusMinimumBalance} available</em>
                  </p>
                  <p className="mb-0 text-small text-thin">
                    <em>
                      This excludes ${MINIMUM_ACCOUNT_BALANCE} which must be
                      left in your account for it to remain open.
                    </em>
                  </p>
                </div>
              ),
            },
          ]}
          name="withdrawalType"
        />
      </Card>
      <div className="flex-row mt-lg">
        <Button variant="link" onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-auto" type="submit" disabled={isDisable}>
          Continue
        </Button>
      </div>
    </form>
  )
}
