import './PerformanceDifference.scss'
import { ReactComponent as IconTrendingUp } from '../../assets/images/icons/icon_trending_up.svg'
import { ReactComponent as IconTrendingDown } from '../../assets/images/icons/icon_trending_down.svg'
import { formatPercentage } from 'shared'

interface PerformanceDifferenceProps {
  value: number
}

const getColorClass = (value: number) => {
  return value < 0 ? 'performance-negative' : 'performance-positive'
}

export default function PerformanceDifference(
  props: PerformanceDifferenceProps
) {
  const { value } = props
  const Icon = value >= 0 ? IconTrendingUp : IconTrendingDown
  return (
    <span className={`performance ${getColorClass(value)}`}>
      {formatPercentage(value)}
      <Icon className="currency__trend-icon" width="18px" />
    </span>
  )
}
