import { Button, formatCurrency, useMedia } from 'shared'
import './WithdrawFundsConfirmation.scss'

type WithdrawFundsConfirmationProps = {
  amount?: number // If not set will show "Withdrawal type: Full"
  accountName?: string // If not set will show "Bank Account: Use existing account"
  accountNumber?: string
  onDone: () => void
}

const WithdrawFundsConfirmation = ({
  amount,
  accountName,
  accountNumber,
  onDone,
}: WithdrawFundsConfirmationProps) => {
  const { isLarge } = useMedia()

  const buildSummaryItem = (label: string, value: string) => (
    <>
      <p>{label}</p>
      <p className="mb-xs color-midnight">
        <strong>{value}</strong>
      </p>
    </>
  )

  return (
    <div className="withdraw-confirmation">
      <p className="mt-0 mb-md">
        Thank you, we have received your withdrawal request.
      </p>

      <div className="summary mb-md">
        {!!amount
          ? (buildSummaryItem('Type', 'Partial Withdrawal'),
            buildSummaryItem('Amount', formatCurrency(amount, '$0,0.00')))
          : buildSummaryItem('Type', 'Full')}

        {!!accountName
          ? buildSummaryItem('Account name', accountName)
          : buildSummaryItem(
              'Bank account',
              'Make the payment to the same bank account used for my most recent withdrawal'
            )}

        {!!accountNumber && buildSummaryItem('Account number', accountNumber)}
      </div>
      <p>
        Once we’ve verified your request we will process your withdrawal.
        Payments are usually made within 5 working days.
      </p>
      <p>
        To protect the security of your account, we may call to confirm your
        withdrawal request and ask for further supporting documentation.
      </p>
      <div className="flex-row-justify-content-end my-sm">
        <Button
          className="text-regular"
          type="submit"
          variant="filled"
          color="primary"
          size={isLarge ? 'md' : 'sm'}
          onClick={() => onDone()}
        >
          Done
        </Button>
      </div>
    </div>
  )
}

export default WithdrawFundsConfirmation
