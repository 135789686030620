import {
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { ReactComponent as IconAddAccount } from '../../assets/images/icons/icon_add_account.svg'
import CloseIcon from '@material-ui/icons/Close'
import { UserData } from '../../redux/user/user.model'
import useOpenJoinApp from '../../hooks/use-open-join-app'
import { useMedia } from 'shared'

type NewAccountCardProps = {
  user: UserData
  hasOwnKiwiSaverAccount: Boolean
  handleClick: () => void
}
export const NewAccountCard = ({
  user,
  hasOwnKiwiSaverAccount,
  handleClick,
}: NewAccountCardProps) => {
  const { openManagedFundsJoinApp } = useOpenJoinApp(user)
  const { isMobile } = useMedia()

  return (
    <ButtonBase
      className={`new-account-card ${isMobile ? 'py-xl' : 'py-2xl'}`}
      onClick={hasOwnKiwiSaverAccount ? openManagedFundsJoinApp : handleClick}
    >
      <IconAddAccount fontSize="large" />
      <Typography className="new-account-card__label" component="p">
        Add a new account
      </Typography>
    </ButtonBase>
  )
}

type NewAccountOptionModalProps = {
  open: boolean
  user: UserData
  onClose: () => void
}

export const NewAccountOptionModal = ({
  user,
  open,
  onClose,
}: NewAccountOptionModalProps) => {
  const { openKiwiSaverJoinApp, openManagedFundsJoinApp } = useOpenJoinApp(user)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className="new-account-modal"
      transitionDuration={{ enter: 200, exit: 0 }}
    >
      <Typography variant="h5">Add a new account</Typography>
      <IconButton onClick={onClose} className="close-button">
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography className="new-account-modal-intro">
          We have flexible investment options with varying degrees of risk to
          help you achieve financial <br />
          freedom down the track.
        </Typography>
        <Grid
          container
          alignItems="stretch"
          spacing={3}
          className="new-account-modal-content-main"
        >
          <AccountOptionCard
            title="KiwiSaver"
            content="KiwiSaver could be your key to the future. The longer and more you invest in your KiwiSaver account, the more money you could have either to buy a first home or to fund your retirement."
            buttonLabel="Join or switch now"
            isKiwiSaver
            onClick={openKiwiSaverJoinApp}
            onClose={onClose}
            openNewWindow
          />
          <AccountOptionCard
            title="Managed Funds"
            content="Managed Funds make investing simple. Whether you’re just starting on your investment journey or you’re well on your way, our award-winning Investment Team take the hassle out of investing."
            buttonLabel="Apply now"
            onClick={openManagedFundsJoinApp}
            onClose={onClose}
            openNewWindow
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

type AccountOptionCardType = {
  title: string
  content: string
  isKiwiSaver?: boolean
  buttonLabel: string
  onClick: () => void
  openNewWindow?: boolean
  onClose: () => void
}

const AccountOptionCard = ({
  title,
  content,
  isKiwiSaver,
  buttonLabel,
  onClick,
  onClose,
}: AccountOptionCardType) => {
  const handleClick = () => {
    onClick()
    onClose()
  }

  return (
    <Grid item xs={12} sm={6}>
      <Card className={`option-card ${isKiwiSaver ? 'kiwisaver' : ''}`}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            className={isKiwiSaver ? 'kiwisaver' : ''}
          >
            {title}
          </Typography>
          <Typography gutterBottom>{content}</Typography>
          <CardActions onClick={handleClick}>
            <Typography>{buttonLabel} &rarr;</Typography>
          </CardActions>
        </CardContent>
      </Card>
    </Grid>
  )
}
