import { useEffect, useState } from 'react'
import {
  Button,
  ControlledRadioMenu,
  IInvestmentOption,
  Link,
  RetirementProjectorFormEmployerContributions,
  RetirementProjectorFormEmploymentStatus,
  RetirementProjectorFormSalaryFrequency,
  RetirementProjectorFormYourContributions,
  RetirementProjectorOption,
  RetirementProjectorVoluntaryContributions,
  schemaValidation,
  useToggle,
} from 'shared'
import {
  RetirementProjectorAboutYouInputs,
  RetirementProjectorState,
} from './RetirementProjector.types'
import { useFormik } from 'formik'
import { retirementProjectorAboutYouForm } from './RetirementProjectorAboutYouForm.schema'
import './RetirementProjectorAboutYou.scss'
import ContactUsModal from '../../components/contact-us-modal'
import { ReactComponent as IconHelp } from '../../assets/images/icons/icon_help.svg'
import { ProgressBarContextProvider } from '../../hooks/useProgressBar'
import IPQ from '../../components/ipq/IPQ'
import { Account } from '../../redux/accounts/accounts.model'
import { isCashPlusLegacy, isMixMandate } from '../../common/accounts-helper'
import {
  getInvestmentOptionByCode,
  isCustomProfile,
} from '../../utils/investmentOption'
import { Product, productHasStrategies } from '../../common/product-helper'
import InvestmentOptionInfoModal from '../../components/investment-option-info/InvestmentOptionInfoModal'
import { AccountDetail } from '../../redux/account-details/account-details.model'

export type RetirementProjectorAboutYouProps = {
  currentAccount: Account
  accountDetails: AccountDetail
  investmentOptions: IInvestmentOption[]
  handleSubmit: (input: RetirementProjectorAboutYouInputs) => void
  contentfulProduct: Product
  state: RetirementProjectorState
}

export const RetirementProjectorAboutYou = (
  props: RetirementProjectorAboutYouProps
) => {
  const {
    currentAccount,
    investmentOptions,
    accountDetails,
    handleSubmit,
    contentfulProduct,
    state,
  } = props

  const { toggle, toggleOn, toggleOff } = useToggle(false)
  const [IPQButtonOpen, setIPQButtonOpen] = useState(false)
  const [showFundSelection, setShowFundSelection] = useState(false)
  const [investmentOptionInfo, setInvestmentOptionInfo] = useState<
    IInvestmentOption
  >(null)

  const form = useFormik<RetirementProjectorAboutYouInputs>({
    initialValues: state.aboutYou,
    validate: schemaValidation<RetirementProjectorAboutYouInputs>(
      retirementProjectorAboutYouForm
    ),
    onSubmit: () => {
      handleSubmit(form.values)
    },
    enableReinitialize: true,
  })

  const strategyOrProductVariant = productHasStrategies(contentfulProduct?.slug)
    ? 'strategy'
    : 'fund'

  useEffect(() => {
    const optionCode = accountDetails?.profile?.profileCode

    const canSelectFundOrStrategy =
      (optionCode && isCustomProfile(optionCode)) ||
      isMixMandate(currentAccount) ||
      isCashPlusLegacy(currentAccount)

    setShowFundSelection(canSelectFundOrStrategy)

    if (!canSelectFundOrStrategy && !!investmentOptions) {
      // if the account is not a mix mandate and it is not a CashPlus fund,
      // it must have only one fund, which is one of the active funds
      // Here it is to find the matching active fund
      const option = getInvestmentOptionByCode(
        contentfulProduct,
        optionCode
          ? optionCode
          : currentAccount && currentAccount.funds[0]?.assetCode
      )
      // We manually set the fund value here to pass the `required` validation on this field
      form.setFieldValue('option', option)
    }
  }, [currentAccount, currentAccount?.funds, investmentOptions, accountDetails]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="retirement-projector__about-you">
        <h4>A bit more about you</h4>
        <p className="retirement-projector__paragraph mb-md text-large">
          Let us know a bit more information so we can work out what your
          KiwiSaver balance and regular income might be when you&apos;re 65.
        </p>
        <div className="retirement-projector__about-you__form section-padding">
          <ol>
            <li>
              <p className="text-regular text-bold">
                Your first home withdrawal
              </p>
              <div className="retirement-projector__about-you__section">
                <ControlledRadioMenu
                  form={form}
                  className="retirement-projector__about-you__radio-group"
                  name="withdrawForFirstHome"
                  label="Are you planning on using your KiwiSaver investment towards your first home?"
                  labelSize="sm"
                  direction="horizontal"
                  size="small"
                  options={[
                    { label: 'Yes', value: true },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              </div>
            </li>

            <hr className="mt-0" />

            {form.values?.withdrawForFirstHome === false ? (
              <>
                <li>
                  <p className="text-regular text-bold">
                    Your employment details
                  </p>
                  <div className="retirement-projector__about-you__section">
                    <RetirementProjectorFormEmploymentStatus
                      form={form}
                      className="retirement-projector__about-you__radio-group"
                    />
                    <div className="retirement-projector__grid">
                      <RetirementProjectorFormSalaryFrequency
                        form={form}
                        className="retirement-projector__about-you__input"
                      />
                    </div>
                  </div>
                </li>
                <hr className="mt-2xs" />
                <li>
                  <p className="text-regular text-bold">
                    Your current KiwiSaver contributions
                  </p>
                  <div className="retirement-projector__grid">
                    <RetirementProjectorFormYourContributions
                      selectedValue={form.values.contributionRate}
                      onChange={(value) =>
                        form.setFieldValue('contributionRate', value)
                      }
                    />
                    <RetirementProjectorFormEmployerContributions
                      selectedValue={form.values.employerContributionRate}
                      onChange={(value) =>
                        form.setFieldValue('employerContributionRate', value)
                      }
                    />
                    <RetirementProjectorVoluntaryContributions
                      amountValue={form.values.voluntaryContribution}
                      onAmountChange={(value) => {
                        form.setFieldTouched(
                          'voluntaryContribution',
                          true,
                          true
                        )
                        form.setFieldValue('voluntaryContribution', value)
                      }}
                      frequencyValue={
                        form.values.voluntaryContributionFrequency
                      }
                      onFrequencyChange={(value) =>
                        form.setFieldValue(
                          'voluntaryContributionFrequency',
                          value
                        )
                      }
                    />
                  </div>
                </li>
                {showFundSelection && (
                  <>
                    <hr className="mt-0" />
                    <li>
                      <div>
                        <p className="text-regular text-bold">
                          Select your investment {strategyOrProductVariant}
                        </p>
                        <p className="text-small">
                          Choose the investment {strategyOrProductVariant} that
                          best matches your existing investment{' '}
                          {strategyOrProductVariant}
                        </p>
                        <div className="retirement-projector__grid">
                          {(investmentOptions || []).map((option) => (
                            <RetirementProjectorOption
                              key={option.shortName}
                              option={option}
                              onSelect={() => {
                                form.setFieldValue('option', option)
                              }}
                              selected={
                                form.values.option?.telCode === option.telCode
                              }
                              error={
                                !!form.errors.option && !!form.touched.option
                              }
                              onMoreInfoClick={(option) =>
                                setInvestmentOptionInfo(option)
                              }
                            />
                          ))}
                        </div>
                        {form.errors.option && form.touched.option && (
                          <p className="retirement-projector__about-you__fund-selection--error">
                            {form.errors.option as any}
                          </p>
                        )}
                        <Button
                          variant="link"
                          className="mt-md text-underline"
                          onClick={() => {
                            setIPQButtonOpen(true)
                          }}
                        >
                          Find out which {strategyOrProductVariant} suits you
                        </Button>
                      </div>
                    </li>
                  </>
                )}
                <hr className="my-lg" />

                <Button
                  className="ml-auto"
                  variant="filled"
                  color="primary"
                  disabled={!state.dalId}
                  onClick={() => {
                    form.handleSubmit()
                  }}
                >
                  View your projection
                </Button>
              </>
            ) : (
              <>
                <p className="text-small">
                  If you’re planning to use your KiwiSaver money towards your
                  first home, this Retirement Projector isn’t suitable for you.
                </p>
                <p className="text-small mb-md">
                  To see how your KiwiSaver account could help you buy your
                  first home, you can chat to our friendly team.
                </p>

                <Button
                  className="ml-auto"
                  variant="filled"
                  color="primary"
                  onClick={toggleOn}
                >
                  Contact us
                </Button>
              </>
            )}
          </ol>
        </div>
      </div>

      <Link
        to={`${process.env.REACT_APP_WEBSITE_URL}/retirement-projector-assumptions`}
        target="_blank"
        iconLeft={() => <IconHelp />}
        className="my-md"
      >
        How this retirement projector works
      </Link>

      <ContactUsModal open={toggle} onClose={toggleOff} />
      {IPQButtonOpen && (
        <ProgressBarContextProvider>
          <IPQ
            onClose={() => setIPQButtonOpen(false)}
            onResult={(investmentOption: IInvestmentOption) => {
              form.setFieldValue('option', investmentOption)
              setIPQButtonOpen(false)
            }}
            account={currentAccount}
          />
        </ProgressBarContextProvider>
      )}
      {investmentOptionInfo && (
        <InvestmentOptionInfoModal
          onClose={() => setInvestmentOptionInfo(null)}
          product={contentfulProduct}
          investmentOption={investmentOptionInfo}
        />
      )}
    </>
  )
}
