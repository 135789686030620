import { connect } from 'react-redux'
import { isManagedFundProductAccount } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { UserState } from '../../redux/user/user.model'
import { MFWithdrawFundsModal } from './MFWithdraw/MFWithdrawFundsModal'
import WithdrawFundsModalGeneric from './WithdrawFundsModalGeneric'
import { AppState } from '../../redux/app-state'

export interface WithdrawFundsModalProps {
  account: Account
  user: UserState
  onClose: () => void
  authToken: string
  userId: string
}

const WithdrawFundsModal = (props: WithdrawFundsModalProps) => {
  if (isManagedFundProductAccount(props.account)) {
    return <MFWithdrawFundsModal {...props} />
  }
  return <WithdrawFundsModalGeneric {...props} />
}

const mapStateToProps = (state: AppState) => {
  return {
    authToken: state.authorisation.authorisationToken,
    userId: state.user.userid,
  }
}

export default connect(mapStateToProps)(WithdrawFundsModal)
