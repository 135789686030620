import { Button, Link, Notification } from 'shared'
import CustomIcon from '../../common/CustomIcon'

export interface WithdrawModalProps {
  onClose: () => void
}

const FFIFWithdrawFundsPDFContent = ({ onClose }: WithdrawModalProps) => (
  <>
    <Notification type="info" className="mb-md">
      Online withdrawals are currently unavailable, but will be available in the
      coming months.
    </Notification>

    <p>
      To make a withdrawal please download and complete our withdrawal form
      below and email it to{' '}
      <a href="mailto:enquiries@fisherfunds.co.nz">
        enquiries@fisherfunds.co.nz
      </a>
      .
    </p>
    <p>
      Note: If we receive your completed form before 12pm on a working day, your
      request will usually be processed at the price effective for that day and
      paid into your bank account within four working days. Otherwise, it will
      usually be processed at the price effective the next working day and paid
      into your bank account four days later.
    </p>

    <div className="flex-row mt-md">
      <Button onClick={onClose} variant="link">
        Cancel
      </Button>
      <Link
        className="ml-auto"
        iconLeft={() => <CustomIcon icon="download" />}
        target="_blank"
        to="https://assets.fisherfunds.co.nz/investment-funds-withdrawals-form"
        variant="filled"
      >
        Download the withdrawal form
      </Link>
    </div>
  </>
)

export default FFIFWithdrawFundsPDFContent
