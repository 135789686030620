// mostly copied from fisher funds website
import { getUserAge } from '../../common/user-helper'
import { AccountFund } from '../../redux/account-funds/account-funds.model'
import { Account } from '../../redux/accounts/accounts.model'
import { UserData } from '../../redux/user/user.model'
import {
  ContributionFrequency,
  EmploymentStatus,
  IInvestmentOption,
  RetirementProjectorOutput,
} from 'shared'

export { ContributionFrequency, EmploymentStatus }

export enum RetirementProjectorStep {
  ABOUT_YOU,
  RESULTS,
}

export type RetirementProjectorState = {
  age: number
  currentKiwiSaverBalance: number
  productExternalName: string
  productExternalRef: string
  aboutYou: RetirementProjectorAboutYouInputs
  kiwiSaverFunds: AccountFund[]
  includeSuper: boolean
  dalId: string | undefined
}

export type RetirementProjectorAboutYouInputs = {
  withdrawForFirstHome: boolean
  employmentStatus: EmploymentStatus
  salary?: number
  salaryFrequency: ContributionFrequency
  contributionRate: number
  employerContributionRate: number
  option?: IInvestmentOption
  voluntaryContribution?: number
  voluntaryContributionFrequency: ContributionFrequency
}

export type RetirementProjectorResponse = {
  projectorOutputs: RetirementProjectorOutput
  dalId: string
}

export const createInitialState = (
  user: UserData,
  account: Account
): RetirementProjectorState => {
  const kiwiSaverFunds = account?.funds || []

  return {
    age: getUserAge(user?.birthDate),
    currentKiwiSaverBalance: account.accountTotal,
    productExternalName: account.productExternalName,
    productExternalRef: account.productExternalRef,
    kiwiSaverFunds,
    includeSuper: false,
    dalId: undefined,
    aboutYou: {
      withdrawForFirstHome: false,
      employmentStatus: EmploymentStatus.EMPLOYED,
      salary: undefined,
      salaryFrequency: ContributionFrequency.YEARLY,
      contributionRate: 3,
      employerContributionRate: 3,
      option: undefined,
      voluntaryContribution: 0,
      voluntaryContributionFrequency: ContributionFrequency.WEEKLY,
    },
  }
}
