import React, { useState } from 'react'
import { Button, IconAnchor } from 'shared'
import InfoIcon from '@material-ui/icons/Info'
import { ProgressBar } from '../accounts-overview/ProgressBar'
import AddFundsModal, {
  AddFundsModalProps,
  LateKiwisaverTopUpNote,
} from '../add-funds-modal/AddFundsModal'
import {
  isApproachingContributionDeadline,
  useGovernmentContribution,
} from '../accounts-overview/useGovernmentContribution'
import { GovernmentContributionProps } from '../accounts-overview/GovernmentContribution'
import Modal from '../modal/Modal'
import format from 'date-fns/format'

export type EligilbleViewProps = GovernmentContributionProps &
  Omit<AddFundsModalProps, 'onClose'>

export const EligibleView: React.FC<EligilbleViewProps> = (
  props: EligilbleViewProps
) => {
  const [addFundsModalOpen, setAddFundsModalOpen] = useState<boolean>(false)
  const [showGCModal, setShowGCModal] = useState<boolean>(false)
  const {
    governmentContributionDetail: { topUpAmount, totalContributions },
  } = props
  const {
    accountId,
    externalRef,
    productExternalName,
    ...governmentContributionProps
  } = props
  const {
    kiwiSaverYear,
    current,
    max,
    width,
    lastContributionDay,
  } = useGovernmentContribution(governmentContributionProps)
  const { asAtDate } = governmentContributionProps.governmentContributionDetail
  const SHOW_LATE_TOP_UP_NOTIFICATION = isApproachingContributionDeadline()

  return (
    <>
      <div>
        <p className="text-large details-label flex-row-center">
          {kiwiSaverYear} Government contribution
          <Button
            className="ml-xs"
            variant="link"
            size="lg"
            iconLeft={InfoIcon}
            onClick={() => setShowGCModal(!showGCModal)}
          />
        </p>
        <p className="m-0">
          <span className="details-label">${current}</span>
          <span className="details-description text-large"> / ${max}</span>
        </p>
        {!!asAtDate && (
          <div className="color-smoke mt-2xs mb-sm">
            as at {format(new Date(asAtDate), 'dd/MM/yyyy')}
          </div>
        )}
      </div>

      <ProgressBar
        className="government-contribution__progress-bar"
        width={width}
      />

      {addFundsModalOpen && (
        <AddFundsModal
          accountId={accountId}
          externalRef={externalRef}
          productExternalName={productExternalName}
          onClose={() => setAddFundsModalOpen(false)}
        />
      )}
      {showGCModal && (
        <Modal
          open={showGCModal}
          fullScreen={true}
          title={`Government Contribution ${kiwiSaverYear}`}
          onClose={() => setShowGCModal(false)}
        >
          <p className="mt-0 mb-0 text-large">
            {topUpAmount === 0 ? (
              <span>
                Great! You have maximised your Government contribution of ${max}
                .
              </span>
            ) : (
              <>
                <span>
                  If you top-up
                  <span className="color-dusk"> ${topUpAmount.toFixed(2)}</span>
                  {!SHOW_LATE_TOP_UP_NOTIFICATION && (
                    <>
                      {' '}
                      by{' '}
                      <span className="color-dusk">
                        {lastContributionDay.format('DD')}/06/{kiwiSaverYear}
                      </span>
                    </>
                  )}
                  , you will maximise your Government contribution of ${max}.
                  You have contributed ${totalContributions.toFixed(2)} and are
                  on track to receive a Government contribution of ${current}.
                </span>
                {SHOW_LATE_TOP_UP_NOTIFICATION && <LateKiwisaverTopUpNote />}
              </>
            )}
          </p>
          {current !== max && (
            <Button
              className="align-self-start mt-md"
              size="lg"
              variant="link"
              color="primary"
              iconRight={IconAnchor}
              onClick={() => setAddFundsModalOpen(true)}
            >
              Top up
            </Button>
          )}
          <hr className="mt-lg" />
          <p className="m-0 color-text-light">
            Disclaimer: The calculations are estimated based on member and
            voluntary contributions that have been added to your KiwiSaver
            account with Fisher Funds. Member contributions can take some time
            to reach us from Inland Revenue, and do not reflect any
            contributions returned to Inland Revenue as an Employer Monthly
            Schedule (EMS) refund. If you’re currently only contributing via
            your salary/wages, we highly recommend checking your myIR account
            for your most accurate contributions summary.
          </p>
        </Modal>
      )}
    </>
  )
}
