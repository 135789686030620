import cn from 'classnames'
import './AdviceDisclosure.scss'
import { Link } from 'shared'
import { Notification } from 'shared'

type AdviceDisclosureProps = {
  className?: string
  onClick?: () => void
}
const AdviceDisclosure = ({ className, onClick }: AdviceDisclosureProps) => (
  <Notification className={cn({ [`${className}`]: !!className })}>
    <h6 className="advice__callout-title mb-xs">
      Our financial advice services
    </h6>
    <p className="advice__callout-text my-0">
      Please read the{' '}
      <Link
        to="https://fisherfunds.co.nz/assets/DS/Fisher-Funds-Advice-Disclosure.pdf"
        target="_blank"
        rel="noreferrer noopener"
        onClick={onClick}
      >
        Fisher Funds’ Advice Disclosure
      </Link>{' '}
      to find out about our financial advice services.
    </p>
  </Notification>
)
export default AdviceDisclosure
