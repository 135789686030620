import isNumber from 'lodash/isNumber'
import format from 'date-fns/format'
import startOfYear from 'date-fns/startOfYear/index'
import CurrencyDifference from '../currency/CurrencyDifference'
import PerformanceDifference from '../performance-difference/PerformanceDifference'
import './AccountCardReturns.scss'

export type AccountCardReturnsProps = {
  marketEarnings: number
  commenceDate: string
  yearToDatePercentageReturn?: number
  annualisedPercentageReturn?: number
}

export const AccountCardReturns = ({
  marketEarnings,
  commenceDate,
  annualisedPercentageReturn,
  yearToDatePercentageReturn,
}: AccountCardReturnsProps) => {
  if (!!yearToDatePercentageReturn) {
    return (
      <div className="account-card-returns flex-row-justify-content-space-between">
        <div className="flex-column">
          <span className="return-label mb-2xs">Year to date return</span>
          <span className="return-description">
            Since {format(startOfYear(new Date()), 'd MMM yyyy')}
          </span>
        </div>
        <PerformanceDifference value={yearToDatePercentageReturn} />
      </div>
    )
  }

  if (!!annualisedPercentageReturn) {
    return (
      <div className="account-card-returns flex-row-justify-content-space-between">
        <div className="flex-column">
          <span className="return-label mb-2xs">Average return</span>
          <span className="return-description">
            Since your first investment
          </span>
        </div>
        <PerformanceDifference value={annualisedPercentageReturn} />
      </div>
    )
  }

  return (
    <div className="account-card-returns flex-row-justify-content-space-between">
      <div className="flex-column">
        <span className="return-label mb-2xs">Investment earnings</span>
        {!!commenceDate && (
          <span className="return-description">
            Since {format(new Date(commenceDate), 'd MMM yyyy')}
          </span>
        )}
      </div>
      {isNumber(marketEarnings) ? (
        <CurrencyDifference value={marketEarnings} />
      ) : (
        <div className="account-card__returns__loading-shimmer" />
      )}
    </div>
  )
}
