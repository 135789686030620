import cn from 'classnames'
import { formatCurrency } from 'shared'
import './Currency.scss'

interface CurrencyProps {
  value: number
  format?: string
  className?: string
  size?: 'md' | 'lg'
}

/**
 * Displays currency with a dollar sign
 */
export default function CurrencyTotal(props: CurrencyProps) {
  const { value, format = '0,0.00', className, size = 'md' } = props
  return (
    <span
      className={cn(`currency__total currency--${size}`, {
        [className]: !!className,
      })}
    >
      <span className="currency__dollar-sign">$</span>
      {formatCurrency(value, format)}
    </span>
  )
}
