import { useState } from 'react'
import cn from 'classnames'
import { FormControl, Select } from '@material-ui/core'
import CallMadeIcon from '@material-ui/icons/CallMade'
import {
  Button,
  getProductInvestmentOptionsPagePath,
  IInvestmentOption,
  InvestmentOptionType as InvestmentTypeEnum,
  useMedia,
} from 'shared'
import {
  getInvestmentOptionByCode,
  getShortName,
  removeStrategyOrFundFromName,
} from '../../utils/investmentOption'
import CurrentIndicator from '../../common/current-indicator/CurrentIndicator'
import { PRODUCT_PDS_FILES, Product } from '../../common/product-helper'
import { ProductCode, ProductSlug } from '../../common/product-variables'
import InvestmentOptionInfoModal from '../investment-option-info/InvestmentOptionInfoModal'
import InvestmentOptionDetails from './InvestmentOptionDetails'
import CustomInvestmentOptionDetails, {
  CustomInvestmentProps,
} from './CustomInvestmentOptionDetails'
import './InvestmentOption.scss'
import { getGlidePathData, isGlidePath } from '../../utils/strategy'
import GlidePathGraph from './GlidePathGraph'

export type OtherInvestmentOption = {
  telCode: string
  shortName: string
}
export type InvestmentOptionType = IInvestmentOption &
  Partial<CustomInvestmentProps>
export type InvestmentOptionProps = {
  className?: string
  product?: Product
  setDisplayedInvestmentOption?: (result: IInvestmentOption) => void
  investmentOption: InvestmentOptionType
  isCurrent?: boolean
  onSelect?: (investmentOption: InvestmentOptionType) => void
  otherInvestmentOptions?: OtherInvestmentOption[]
  isApexProduct?: boolean
}
const InvestmentOption = ({
  className,
  product,
  investmentOption,
  isCurrent,
  setDisplayedInvestmentOption,
  onSelect,
  otherInvestmentOptions,
}: InvestmentOptionProps) => {
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false)
  const { isLarge } = useMedia()

  const getInvestmentOptionType = () =>
    investmentOption.type === InvestmentTypeEnum.STRATEGY ? 'Strategy' : 'Fund'

  const { isCustom } = investmentOption

  const showPercentageFundAllocated =
    [ProductSlug.FF_TWO, ProductSlug.FFMF].includes(product.slug) &&
    !isCustom &&
    investmentOption.strategy?.strategyFundAllocations?.length &&
    !investmentOption.strategy?.ageRelatedStrategy

  const renderMoreInfoButton = () => {
    if (isCustom) {
      return null
    }
    return (
      <Button
        iconRight={CallMadeIcon}
        className="ml-sm"
        size="sm"
        variant="link"
        onClick={() => setMoreInfoModalOpen(true)}
      >
        More Info
      </Button>
    )
  }

  const shortName = getShortName(investmentOption)
  const headerName = isCustom
    ? shortName // We want custom strategy to show the word "strategy";
    : removeStrategyOrFundFromName(shortName)

  const isKPOrIF = [ProductSlug.FFKP, ProductSlug.FFIF].includes(product.slug)

  const renderCustomOrMixMandateSummary = () => {
    if (isKPOrIF) {
      return (
        <p className="text-small">
          You are invested in a mix of funds. You can see the description of
          each of these funds here by downloading the{' '}
          <a
            href={PRODUCT_PDS_FILES[product.slug]}
            target="_blank"
            rel="noreferrer noopener"
          >
            Product Disclosure Statement
          </a>
        </p>
      )
    }

    return (
      <p className="text-small">
        Your {!!isCurrent ? 'current' : 'new'} strategy is below. You can see a
        description of the funds by visiting the{' '}
        <a
          href={getProductInvestmentOptionsPagePath(product.slug, null, true)}
          target="_blank"
          rel="noreferrer noopener"
        >
          Fisher Funds Website
        </a>
        .
      </p>
    )
  }

  const renderInvestmentOptionDetails = () => {
    if (isCustom) {
      return (
        <CustomInvestmentOptionDetails
          investmentOption={investmentOption}
          isCurrent={isCurrent}
          product={product}
        />
      )
    }

    if (isGlidePath(investmentOption.strategy)) {
      const graphData = getGlidePathData(product)
      return <GlidePathGraph {...graphData} />
    }

    return (
      <InvestmentOptionDetails
        product={product}
        investmentOption={investmentOption}
      />
    )
  }

  return (
    <>
      {moreInfoModalOpen && (
        <InvestmentOptionInfoModal
          onClose={() => setMoreInfoModalOpen(false)}
          product={product}
          investmentOption={investmentOption}
        />
      )}
      <div
        className={cn('ipq-result-option input-tooltip-group', {
          [`${className}`]: !!className,
          'is-current': !!isCurrent,
          'is-custom': !!isCustom,
        })}
      >
        {isCurrent && <CurrentIndicator />}
        <div className="ipq-result-wrapper">
          <p
            className="text-small color-text-light mb-2xs"
            data-test="investment-option-label"
          >
            {getInvestmentOptionType()}
          </p>
          <div className="flex-row-center">
            <h5 className="color-midnight mb-0">{headerName}</h5>
            {product.productCode !== ProductCode.FF_LIFE &&
              renderMoreInfoButton()}
          </div>
          {showPercentageFundAllocated &&
            investmentOption.strategy.strategyFundAllocations?.map((s) => (
              <p className="text-small color-text-light my-0" key={s.fund.name}>
                {s.allocationPercentage}% {s.fund.shortName}
              </p>
            ))}
          <p className="section-header">
            Summary of investment objectives and{' '}
            {getInvestmentOptionType().toLocaleLowerCase()}
          </p>
          {isCustom ? (
            renderCustomOrMixMandateSummary()
          ) : (
            <p className="result-summary text-small">
              {investmentOption.summary}
            </p>
          )}
          {renderInvestmentOptionDetails()}
        </div>

        <div className="result-actions">
          {!!investmentOption && (
            <Button
              className="mr-auto mt-sm"
              onClick={() => {
                onSelect(investmentOption)
              }}
              size={isLarge ? 'md' : 'sm'}
            >
              {isCurrent ? 'Remain in' : 'Change to'} {shortName}
            </Button>
          )}
          {!!setDisplayedInvestmentOption && (
            <>
              <FormControl
                size="small"
                fullWidth
                variant="outlined"
                margin="normal"
              >
                <Select
                  className="option-select"
                  native
                  variant="outlined"
                  labelId="ipq-option-select-label"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setDisplayedInvestmentOption(
                      getInvestmentOptionByCode(
                        product,
                        event.target.value as string
                      )
                    )
                  }}
                  value="placeholder"
                >
                  <option value="placeholder" disabled selected>
                    View other options
                  </option>
                  {otherInvestmentOptions.map((option: any) => (
                    <option key={option.telCode} value={option.telCode}>
                      {option.shortName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InvestmentOption
