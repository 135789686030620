import { ReturnsSummaryItem } from 'shared'
import { ProductCode } from './product-variables'

type ProductReturnMap = {
  product: ProductCode
  [key: string]: string
}

// Used to map the performance api to the matching fund or strategy.
// We should only use this for the ones we can't map by registry code. E.g. strategies
export const SHORTNAME_PERFORMANCE_API_DISPLAY_NAME_MAP: ProductReturnMap[] = [
  {
    product: ProductCode.FFKS,
    'Conservative Strategy': 'Conservative Fund',
    'Balanced Strategy': 'Balanced Strategy++',
    'Growth Strategy': 'Growth Fund',
  },
  {
    product: ProductCode.FF_TWO,
    'Conservative Strategy': 'Conservative Fund',
    'Balanced Strategy': 'Balanced Fund',
    'Growth Strategy': 'Growth Fund',
  },
  {
    product: ProductCode.FFMF,
    'Conservative Strategy': 'Conservative Fund',
    'Balanced Strategy': 'Balanced Strategy 40/60',
    'Balanced Strategy 45/55': 'Balanced Strategy 45/55',
    'Growth Strategy': 'Growth Fund',
  },
  {
    product: ProductCode.FF_LIFE,
    'Conservative Strategy': 'Conservative Fund',
    'Balanced Strategy': 'Balanced Fund',
    'Growth Strategy': 'Growth Fund',
  },
]

export const getReturnByDisplayName = (
  items: ReturnsSummaryItem[],
  productCode: string,
  shortName: string
) => {
  const SHORTNAME_DISPLAY_NAME_MAP = SHORTNAME_PERFORMANCE_API_DISPLAY_NAME_MAP.find(
    (p) => p.product.includes(productCode)
  )

  const displayName = shortName.includes('Strategy')
    ? SHORTNAME_DISPLAY_NAME_MAP[shortName]
    : shortName
  return items.find((item) => item.displayName.includes(displayName))
}
