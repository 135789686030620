import { FundAllocation } from '../components/switch-contentful-modal/CustomStrategySelector/CustomStrategySelector'
import { Account } from '../redux/accounts/accounts.model'
import {
  hasWriteAuthorisation,
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
} from './accounts-helper'
import { Product } from './product-helper'
import {
  INVESTMENT_FUNDS,
  KIWISAVER_ONE,
  KIWISAVER_PLAN,
  KIWISAVER_TWO,
  MANAGED_FUNDS_APEX,
  MANAGED_FUNDS_TEL,
  ProductSlug,
} from './product-variables'

export const isApexSwitchEnabled =
  process.env.REACT_APP_FEATURE_TOGGLE_APEX_SWITCH !== 'false'

// The following products have a switch definition in contentful
const switchContentfulDefs = [
  KIWISAVER_ONE,
  KIWISAVER_TWO,
  MANAGED_FUNDS_TEL,
  ...(isApexSwitchEnabled
    ? [KIWISAVER_PLAN, INVESTMENT_FUNDS, MANAGED_FUNDS_APEX]
    : []),
]

export const hasContentfulSwitchDef = (productExternalRef: string): boolean => {
  return switchContentfulDefs.includes(productExternalRef)
}

export const canSwitchModal = (account: Account) => {
  if (!account || !!account.isDepositMode) {
    return false
  }
  return (
    hasWriteAuthorisation(account) &&
    switchContentfulDefs.includes(account.productExternalRef)
  )
}

export const splitSums100 = (split: FundAllocation[]) =>
  split.reduce((sum, item) => sum + item.ratio, 0) === 100

export const showFutureSplitOnly = (account: Account) =>
  isInvestmentFundsAccount(account)

export const showFutureSplitOnlyProduct = (product: Product) =>
  product.slug === ProductSlug.FFIF
