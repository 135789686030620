import { InvestorType } from 'shared'

export type IPQOption = {
  id: number
  text: string
}

export enum IPQStep {
  INTRO_SCREEN,
  QUESTIONNAIRE,
  RESULTS,
  SWITCH_PENDING,
}
export type IPQPersonalDetailsInputs = {
  firstName: string
  lastName: string
  phone: string
  email: string
  isExistingClient?: boolean
}

export interface IPQQuestion {
  id: number
  text: string
  type: string
  options: IPQOption[]
}

export type IPQTemplate = {
  templateId: number
  sections: IPQSection[]
}

export interface IPQSection {
  id: number | string
  name: string
  questions: IPQQuestion[]
}

export interface IPQQuestionnaireResultTypes {
  name: string
  possibleResults: string[]
}

export type IPQFormValues = {
  answers: IPQQuestionnaireAnswer[]
  templateId: number
}

export type IPQQuestionnaireAnswer = {
  questionId: number
  questionLabel: string
  optionId: number
  optionLabel: string
}

// dalId: The object ID of Fsc Digital Advice Log, this
// is used to update the object as the IPQ is progressed
export type IPQPostResponse = {
  dalId: string
}

export type IPQPostRequest = {
  firstName: string
  lastName: string
  email: string
  mobile: string
  source: string
  productShortCode: string
  status: 'Complete' | 'Incomplete'
}

export type IPQPutResponse = {
  score: number
  result: InvestorType
}

export type IPQPutRequest = {
  answers: {
    question: {
      id: number
      text: string
    }
    option: {
      id: number
      text: string
    }
  }[]
  productShortCode: string
  status: string
  templateId: number
  outcome: {
    type: 'Remain' | 'Switch'
    option: string
  }
  accountNumber: string
}
