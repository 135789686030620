import { useEffect, useMemo } from 'react'
import {
  BalanceGraphData,
  GraphConfiguration,
} from '../../redux/account-balance/account-balance.model'
import { AppState } from '../../redux/app-state'
import { Dispatch, bindActionCreators } from 'redux'
import { AccountBalanceRequestAction } from '../../redux/account-balance/account-balance.actions'
import { connect } from 'react-redux'
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  Line,
  Legend,
  ReferenceLine,
} from 'recharts'
import {
  GRAPH_COLORS,
  commonAxisProps,
  getGraphConfigurationTypeDateFormat,
  getXIntervals,
  getYAxisWidth,
  getYInterval,
} from '../../common/graph-helper'
import { formatCurrency } from 'shared'

import './BalanceGraph.scss'
import { useMedia } from 'shared'
import { TooltipContent } from './BalanceGraphTooltip'
import moment from 'moment'

interface BalanceGraphProps {
  userId: string
  accountNumber: string
  balanceGraph?: BalanceGraphData
  graphConfiguration?: GraphConfiguration
  getBalanceGraph?: (userId: string, accountNumber: string) => void
  isLoading?: boolean
  hasError?: boolean
}

const BalanceGraph = (props: BalanceGraphProps) => {
  const {
    userId,
    accountNumber,
    balanceGraph,
    graphConfiguration,
    getBalanceGraph,
    isLoading,
    hasError,
  } = props

  const { isMobile } = useMedia()
  const graphHeight = isMobile ? 250 : 350

  useEffect(() => {
    getBalanceGraph(userId, accountNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, graphConfiguration])

  const graphComponents = useMemo(() => {
    const balancePeriods = balanceGraph?.balancePeriods || []

    // Kiwi Wealth accounts don't provide accumCashFlow
    const hasAccumCashFlow = balancePeriods.some((p) => !!p.accumCashFlow)

    const highestYValue = balancePeriods.reduce(
      (acc, curr) => Math.max(acc, curr.marketValue, curr.accumCashFlow),
      -Infinity
    )
    const lowestYValue = balancePeriods.reduce(
      (acc, curr) =>
        Math.min(
          acc,
          curr.marketValue,
          hasAccumCashFlow ? curr.accumCashFlow : Infinity
        ),
      Infinity
    )

    const xIntervals = getXIntervals(balancePeriods)
    const yInterval = getYInterval(lowestYValue, highestYValue)
    const yAxisTickCount =
      Math.ceil(highestYValue / yInterval) -
      Math.floor(lowestYValue / yInterval) +
      1
    const yAxisWidth = getYAxisWidth(highestYValue)

    return (
      <ComposedChart data={balancePeriods} margin={{ right: 20 }}>
        <CartesianGrid
          strokeWidth={1}
          strokeDasharray="9 4"
          syncWithTicks
          stroke={GRAPH_COLORS.LINE}
        />
        <XAxis
          {...commonAxisProps}
          dataKey="toDate"
          ticks={xIntervals}
          tickMargin={10}
          tickFormatter={(value: string) =>
            moment(value).format(
              getGraphConfigurationTypeDateFormat(graphConfiguration.type)
            )
          }
        />
        <YAxis
          {...commonAxisProps}
          tickCount={yAxisTickCount}
          width={yAxisWidth}
          tickFormatter={(number: number) => formatCurrency(number, '$0,0')}
          domain={[
            (dataMin: number) => Math.floor(dataMin / yInterval) * yInterval,
            (dataMax: number) => Math.ceil(dataMax / yInterval) * yInterval,
          ]}
          padding={{ top: 10 }}
        />
        <Tooltip content={<TooltipContent />} />
        {!!hasAccumCashFlow && (
          <Line
            dataKey="accumCashFlow"
            stroke={GRAPH_COLORS.MIDNIGHT}
            strokeWidth={1}
            strokeDasharray="5 5"
            dot={false}
          />
        )}
        <ReferenceLine y={0} />
        <Area
          dataKey="marketValue"
          stroke={GRAPH_COLORS.DUSK}
          fill={GRAPH_COLORS.SKY}
          fillOpacity={0.25}
          baseValue={Math.floor(lowestYValue / yInterval) * yInterval}
        />
        <Legend
          iconType="plainline"
          formatter={(value: string, entry: any) => (
            <span>
              {value === 'marketValue'
                ? 'Your portfolio'
                : 'Your contributions'}
            </span>
          )}
        />
      </ComposedChart>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceGraph, isMobile])

  // This will get replaced by proper error and loading states once designed and approved
  if (isLoading || hasError)
    return (
      <div className="balance-graph__message mt-lg">
        <h6>
          {isLoading
            ? 'Loading performance data...'
            : 'No performance data found'}
        </h6>
      </div>
    )

  return (
    <ResponsiveContainer height={graphHeight} className="mt-lg">
      {graphComponents}
    </ResponsiveContainer>
  )
}

const mapStateToProps = (state: AppState, props: BalanceGraphProps) => {
  const { accountNumber } = props

  let balanceGraph = state.accountBalance.balanceGraphs.find(
    (balanceGraph) =>
      balanceGraph.accountNumber === accountNumber &&
      balanceGraph.graphConfigurationDateType ===
        state.accountBalance.graphConfiguration.dateType
  )

  return {
    balanceGraph: balanceGraph,
    graphConfiguration: state.accountBalance.graphConfiguration,
    isLoading: state.accountBalance.isLoading,
    hasError: state.accountBalance.hasError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<BalanceGraphProps>) => ({
  getBalanceGraph: bindActionCreators(AccountBalanceRequestAction, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(BalanceGraph)
