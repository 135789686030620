import * as React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import {
  UserActions,
  UserRequestDetailsAction,
} from '../../redux/user/user.actions'
import Alert from 'react-s-alert'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import ContactUsModal from '../../components/contact-us-modal'
import { useToggle } from 'shared'
import { Notification } from 'shared'

import './PersonalDetails.scss'

interface PersonalDetailsProps {
  userid?: string
  fullName?: string
  birthDate?: string
  ird?: string
  hasError?: boolean
  errorMessage?: string
  isLoading?: boolean
  actions?: {
    getUserDetails: (uuid: string) => void
  }
}

const ContactUsLink = () => {
  const { toggle, toggleOn, toggleOff } = useToggle(false)

  return (
    <>
      <ContactUsModal open={toggle} onClose={toggleOff} />
      <Link color="secondary" underline="always" onClick={toggleOn}>
        please contact us
      </Link>
    </>
  )
}

export class PersonalDetails extends React.Component<PersonalDetailsProps> {
  UNSAFE_componentWillMount() {
    this.props.actions.getUserDetails(this.props.userid)
  }

  UNSAFE_componentWillReceiveProps(nextProps: PersonalDetailsProps) {
    // If we're about to change from 'no error' to 'error', show an alert
    if (!this.props.hasError && nextProps.hasError) {
      Alert.error(nextProps.errorMessage, {
        timeout: 'none',
      })
    }
  }

  render() {
    return (
      <>
        <Typography component="h1" variant="h5">
          Personal Details
        </Typography>

        <div className="my-details-wrapper">
          <div className="left-content-details">
            <Typography component="p" variant="caption">
              Name
            </Typography>
            <Typography component="p" variant="h6">
              {this.props.fullName}
            </Typography>
          </div>

          <div className="right-content-details">
            <div>
              <Typography component="p" variant="caption">
                IRD number
              </Typography>
              <Typography component="p" variant="h6">
                {this.props.ird}
              </Typography>
            </div>
          </div>
        </div>
        <Notification className="mt-md" backgroundColor="grey">
          If any of the above details are incorrect <ContactUsLink />.
        </Notification>
      </>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    userid: state.user.userid,
    fullName: `${state.user.firstName} ${
      state.user.secondName ? ` ${state.user.secondName}` : ''
    } ${state.user.surname}`,
    birthDate: state.user.birthDate,
    ird: state.user.ird,
    hasError: state.user.hasError,
    errorMessage: state.user.errorMessage,
    isLoading: state.user.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UserActions>) => ({
  actions: {
    getUserDetails: bindActionCreators(UserRequestDetailsAction, dispatch),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(PersonalDetails)
