import cn from 'classnames'
import { Button, Fund, Strategy } from 'shared'
import CallMadeIcon from '@material-ui/icons/CallMade'
import CurrentIndicator from '../../common/current-indicator/CurrentIndicator'
import './InvestmentOptionSelector.scss'
import { AccountProfile } from '../../redux/account-details/account-details.model'
import { investmentOptionMatchesProfile } from '../../utils/investmentOption'
import InvestmentSwitchNotification from './InvestmentSwitchNotification'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'

type Props = {
  className: string
  profile?: AccountProfile
  investmentOptions: (Fund | Strategy)[]
  onSelect: (code: string) => void // code is salesforceCode or telCode
  onMoreInfoClick: (salesforceCode: string) => void
  onOpenIPQ?: () => void
  productName: string
  isSuspended?: boolean
  isStrategyProductAccount: boolean
  isApexProductAccount?: boolean
  switchNotifications: SwitchNotification[]
}

const InvestmentOptionSelector = ({
  className,
  profile,
  investmentOptions,
  onSelect,
  onMoreInfoClick,
  onOpenIPQ,
  productName,
  isSuspended,
  isStrategyProductAccount,
  isApexProductAccount = false,
  switchNotifications,
}: Props) => {
  const strategyOrFundVariant = isStrategyProductAccount ? 'strategy' : 'fund'
  return (
    <div
      className={cn('different-option-selector', {
        [`${className}`]: !!className,
      })}
    >
      <InvestmentSwitchNotification
        className="mb-md"
        productName={productName}
        isApexProduct={isApexProductAccount}
        isStrategyProduct={isStrategyProductAccount}
        switchNotifications={switchNotifications}
      />
      <h6 className="color-midnight mt-md mb-sm">
        {`Choose a ${strategyOrFundVariant}`}
        {onOpenIPQ && (
          <Button
            className="ml-sm"
            variant="link"
            size="md"
            iconRight={CallMadeIcon}
            onClick={onOpenIPQ}
          >
            Get help choosing your {strategyOrFundVariant}
          </Button>
        )}
      </h6>
      <div className="option-list">
        {investmentOptions.map((option) => {
          const { shortName } = option
          const isCurrent = investmentOptionMatchesProfile(option, profile)
          const investmentType = option.hasOwnProperty('customStrategy')
            ? 'strategy'
            : 'fund'
          return (
            <div
              key={shortName}
              className={cn('investment-option mb-sm', {
                'investment-option--is-current': !!isCurrent,
                'investment-option--is-suspended': !!isSuspended,
              })}
            >
              {isCurrent && <CurrentIndicator />}
              <p className="text-small color-text-light mt-0 mb-2xs text-capitilize">
                {investmentType}
              </p>
              <h5 className="color-midnight">{shortName}</h5>
              <div className="investment-option__actions">
                <Button
                  variant="link"
                  size="sm"
                  iconRight={CallMadeIcon}
                  onClick={() => {
                    onMoreInfoClick(
                      'salesforceCode' in option
                        ? option.salesforceCode
                        : option.telCode
                    )
                  }}
                >
                  More info
                </Button>
                {!isCurrent && (
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={() =>
                      onSelect(
                        'salesforceCode' in option
                          ? option.salesforceCode
                          : option.telCode
                      )
                    }
                    disabled={isSuspended}
                  >
                    {isSuspended ? 'Suspended' : 'Select'}
                  </Button>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default InvestmentOptionSelector
