import { Account } from '../redux/accounts/accounts.model'
import {
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
  isKiwiSaverTwoAccount,
  isManagedFundProductAccount,
} from './accounts-helper'
import { isOverRetirementAge } from './user-helper'

export const canWithdrawFunds = (account: Account, birthDate?: string) => {
  if (!account) {
    return false
  }

  if (isInvestmentFundsAccount(account)) {
    return true
  }

  if (isManagedFundProductAccount(account)) {
    return process.env.REACT_APP_FEATURE_ENABLE_MF_WITHDRAW === 'true'
  }

  if (!birthDate || !isOverRetirementAge(birthDate)) {
    return false
  }

  if (isKiwiSaverPlanAccount(account)) {
    return true
  }

  return (
    isKiwiSaverTwoAccount(account, false) &&
    process.env.REACT_APP_FEATURE_ENABLE_FF2_WITHDRAW === 'true'
  )
}
